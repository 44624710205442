import { makeCollectionQuery, makeApiMutation } from "@web-monorepo/shared/reactQuery";

export const POLLING_INTERVAL_10_MINUTES = 10 * 60 * 1000;

export const useNotificationsFetcher = makeCollectionQuery({
  fetcherName: "notifications",
  path: "/api/notification",
  queryParams: ["targetId", "targetType"],
  dontThrowOnStatusCodes: [403],
  refetchInterval: POLLING_INTERVAL_10_MINUTES,
});

export const useMarkNotificationsReadOperation = makeApiMutation({
  name: "markNotificationRead",
  path: "/api/markNotificationRead",
  method: "post",
  onSuccess: (_data, params) => {
    useNotificationsFetcher.setQueriesData(
      (draft) => {
        draft.forEach((notification) => {
          if ("read" in notification) {
            notification.read = true;
          }
        });
      },
      { targetId: params?.body?.targetId },
    );
  },
});
