import { ColorStyles, NessieContentColorKinds } from "@classdojo/web/nessie/components/theme";
import { RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";

export type GrandstanderTextProps = Omit<React.HTMLAttributes<HTMLSpanElement>, "color" | "fontFamily"> &
  GrandstanderTextConditionalProps & {
    href?: never | string;
    nowrap?: boolean;
    display?: "block" | "inline" | "inline-block" | "flex" | "inline-flex";
    textAlign?: "right" | "left" | "center" | "justify" | "initial" | "inherit";
    as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "li" | "label" | "span" | "dt" | "dd";
    htmlFor?: string;
    kind?: ColorStyles;
    textTransform?: "uppercase" | "lowercase" | "capitalize" | "none" | "onlyFirst";
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
  };

type GrandstanderTextConditionalProps =
  | {
      href?: never;
      onClick?: never;
      target?: never;
    }
  | {
      as?: never;
      onClick?: React.MouseEventHandler;
      href: string;
      target?: "_blank" | "_self" | "_parent" | "_top";
    }
  | {
      as: "label";
      htmlFor: string;
    }
  | {
      as: "label";
      children: React.ReactNode;
    };

const contentColorStyleToToken: Record<ColorStyles, NessieContentColorKinds> = {
  primary: "dt_content_primary",
  secondary: RAW_cssValue("#FFF7B2"),
  tertiary: "dt_content_tertiary",
  light: "dt_content_light",
  disabled: "dt_content_disabled",
  accent: "dt_content_accent",
  success: "dt_content_success",
  warning: "dt_content_warning",
  danger: "dt_content_danger",
  plus: "dt_content_plus",
} as const;

export const GrandstanderText = ({
  as = "span",
  children,
  nowrap,
  display = "block",
  className,
  textAlign = "inherit",
  kind = "primary",
  textTransform = "uppercase",
  ...props
}: GrandstanderTextProps) => {
  const Tag = props.href ? "a" : as;

  const wrappingStyles = nowrap ? noWrapStyle : {};
  const capitalizationStyles: ThemeUIStyleObject =
    textTransform === "onlyFirst"
      ? {
          "&::first-letter": {
            textTransform: "capitalize",
          },
        }
      : { textTransform };
  return (
    <Tag
      className={`grandstander-text ${className}`}
      sx={{
        ...textStyle,
        ...wrappingStyles,
        ...capitalizationStyles,
        textAlign,
        display,
        color: contentColorStyleToToken[kind],
      }}
      {...props}
    >
      {children}
    </Tag>
  );
};

const textStyle: ThemeUIStyleObject = {
  // color: "inherit",
  fontStyle: "inherit",
  fontVariant: "inherit",
  margin: 0,
  padding: 0,
  textDecorationLine: "none",

  // block level by default, but inline when nested
  display: "block",
  ">*": {
    display: "inline !important",
  },

  // default wrapping
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
};

const noWrapStyle: ThemeUIStyleObject = {
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  ">.text": {
    whiteSpace: "inherit",
  },
};
