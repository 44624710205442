import * as React from "react";
import styles from "./NotificationItem.module.css";

import {
  DDSAvatar,
  DDSBadge,
  DDSBodyText,
  DDSMinutiaeText,
  DDSDialog,
  DDSDialogTrigger,
  DDSDialogBody,
  DDSDialogContent,
  DDSDialogFooter,
  DDSDialogHeader,
  DDSButton,
} from "@web-monorepo/dds";
import { makeTimestampString } from "./makeTimestampString";
import { parseJSON } from "@web-monorepo/dates";
import { TranslatedString } from "@web-monorepo/i18next";
import formatUrl from "./formatUrl";
import { type Notification } from "./types";
import MojoAvatar from "./assets/mojo_avatar@2x.png";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";

interface NotificationItemProps {
  notification: Notification;
  onClick?: (ev: React.MouseEvent<Element, MouseEvent>) => void;
}

interface NotificationWrapperProps {
  href?: string;
  onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  secondaryView?: SecondaryView;
  className?: string;
}

const NotificationWrapper: React.FC<React.PropsWithChildren<NotificationWrapperProps>> = ({
  children,
  href,
  className,
  secondaryView,
  onClick,
}) => {
  const [open, setOpen] = React.useState(false);
  if (secondaryView) {
    const innerOnClick: typeof onClick = (e) => {
      setOpen(false);
      if (href) {
        const { newUrl, target } = formatUrl(href);
        if (target === "") {
          window.location.assign(newUrl);
        } else {
          window.open(newUrl);
        }
      } else onClick?.(e);
    };

    return (
      <DDSDialog open={open} onOpenChange={setOpen}>
        <DDSDialogTrigger asChild>
          <button data-name="notification-item" className={className}>
            {children}
          </button>
        </DDSDialogTrigger>
        <DDSDialogContent>
          {secondaryView.header ? (
            <DDSDialogHeader title={secondaryView.header} />
          ) : (
            <DDSDialogHeader title={autoTranslate("Notification")} />
          )}
          <DDSDialogBody>
            {secondaryView.image && <img alt="" src={secondaryView.image} className={styles.modalImage} />}
            {secondaryView.text && <DDSBodyText>{secondaryView.text as TranslatedString}</DDSBodyText>}
          </DDSDialogBody>
          <DDSDialogFooter>
            <DDSButton variant="primary" data-name="announcement_modal:cta" onClick={innerOnClick}>
              {secondaryView.cta}
            </DDSButton>
          </DDSDialogFooter>
        </DDSDialogContent>
      </DDSDialog>
    );
    // return a dialog with the children as the trigger!
  }

  if (href) {
    // TODO: Gracefully handle direct universal links as "real" links (not sure how?)
    return (
      <a
        href={href}
        className={className}
        target={href.startsWith("?") || href.startsWith("#") || href.startsWith("/") ? undefined : "_blank"}
      >
        {children}
      </a>
    );
  } else if (onClick) {
    return (
      <button data-name="notification-item" onClick={onClick} className={className}>
        {children}
      </button>
    );
  } else {
    return <div className={className}>{children}</div>;
  }
};

interface SecondaryView {
  cta?: string;
  header?: string;
  image?: string;
  text?: string;
}

export const BaseNotificationItem: React.FC<
  {
    secondaryView?: SecondaryView;
    accessory: React.ReactNode;
    text: React.ReactNode;
    date: Date;
    badge?: React.ReactNode;
  } & NotificationWrapperProps
> = ({ accessory, text, date, badge, href, onClick, secondaryView }) => {
  return (
    <NotificationWrapper
      secondaryView={secondaryView}
      data-name="notification-item"
      className={styles.notification}
      href={href}
      onClick={onClick}
    >
      <div className={styles.accessory}>
        {accessory}
        {badge ? (
          <DDSBadge className={styles.badge} variant="destructive">
            {badge}
          </DDSBadge>
        ) : null}
      </div>
      <div className={styles.content}>
        <DDSBodyText level={2}>{text}</DDSBodyText>
        <DDSMinutiaeText className={styles.date}>{makeTimestampString(date)}</DDSMinutiaeText>
      </div>
    </NotificationWrapper>
  );
};

const MarketingItem: React.FC<
  {
    content: { text: string; image?: string };
    actor: { avatarUrl?: string };
    createdAt: Date;
  } & NotificationWrapperProps
> = ({ onClick, href, actor: { avatarUrl }, content: { text }, createdAt, secondaryView }) => {
  return (
    <BaseNotificationItem
      accessory={<div role="img" aria-hidden="true" style={{ backgroundImage: `url(${avatarUrl ?? MojoAvatar})` }} />}
      date={createdAt}
      text={text}
      href={href}
      onClick={onClick}
      secondaryView={secondaryView}
    />
  );
};

/** getNotificationSecondaryView: this function glosses over the empirical & announced types:
 * - empirical: secondaryView is on the root node
 * - declared: secondaryView is in object subobject (not observed in practice yet, and might be excluded from types)
 */
function getNotificationSecondaryView(notification: Notification): SecondaryView | undefined {
  return (
    ("secondaryView" in notification && notification.secondaryView) ||
    ("object" in notification && notification?.object?.secondaryView)
  );
}
export const NotificationItem: React.FC<NotificationItemProps> = ({ notification, onClick }) => {
  if (notification.actor.type === "dojo") {
    if (!("content" in notification) || !notification.content) {
      return null;
    }

    return (
      <MarketingItem
        actor={notification.actor}
        content={notification.content}
        href={notification.destination?.web}
        createdAt={parseJSON(notification.createdAt!)}
        secondaryView={getNotificationSecondaryView(notification)}
        onClick={onClick}
      />
    );
  }

  // generally just show the actors as an Avatar for the accessory, the text for the text, and createdAt for the date.

  const entityInfo = {
    avatarURL: notification.actor.avatarUrl,
    entityId: notification.actor._id ?? notification.actor.type,
  } as Parameters<typeof DDSAvatar>[0]["entityInfo"];

  if (!("content" in notification)) return null;
  if (!notification.content?.text) return null;
  if (!notification.createdAt) return null;

  return (
    <BaseNotificationItem
      accessory={<DDSAvatar entityInfo={entityInfo} size={"s"} />}
      text={notification.content.text}
      date={parseJSON(notification.createdAt)}
      href={notification.destination?.web}
      secondaryView={getNotificationSecondaryView(notification)}
      onClick={onClick}
    />
  );
};
