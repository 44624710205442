import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { GrandstanderText, GrandstanderTextProps } from "./GrandstanderText";

export type DisplayTextLevelsType = 1 | 2 | 3 | 4;

export type DisplayTextProps = {
  level?: DisplayTextLevelsType;
} & GrandstanderTextProps;

export function IslandsDisplayText({ level = 1, ...props }: DisplayTextProps) {
  return <GrandstanderText sx={displayTextStyles[level]} {...props} />;
}

const displayTextStyles: Record<DisplayTextLevelsType, ThemeUIStyleObject> = {
  1: {
    fontSize: "69px",
    lineHeight: "72px",
    letterSpacing: "-1px",
    fontWeight: "dt_extraBold",
  },
  2: {
    fontSize: "55px",
    lineHeight: "60px",
    letterSpacing: "-1px",
    fontWeight: "dt_extraBold",
  },
  3: {
    fontSize: "44px",
    lineHeight: "48px",
    letterSpacing: "-0.5px",
    fontWeight: "dt_extraBold",
  },
  4: {
    fontSize: "35px",
    lineHeight: "42px",
    letterSpacing: "-0.5px",
    fontWeight: "dt_extraBold",
  },
};
