import i18next from "i18next";
import { type InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import MultiReadBackend from "i18next-multiload-backend-adapter";
import { Backend } from "./backend";
export const i18n = i18next;

i18n.use(MultiReadBackend);
i18n.use(initReactI18next);

// i18n.use(MultiloadBackend);

const FALLBACKS: Record<string, string[]> = {
  "es-MX": ["es-ES", "en"], // Mexican Spanish
  "es-ES": ["es-MX", "en"], // Spanish Spanish
  es: ["es-MX", "es-ES", "en"], // Generalized spanish
  "fr-CA": ["fr-FR", "en"], // Canadian French
  "fr-FR": ["fr-CA", "en"], // French French
  "zh-CN": ["zh-HK", "zh-TW", "en"],
  "zh-TW": ["zh-HK", "zh-CN", "en"],
  "zh-HK": ["zh-TW", "zh-CN", "en"],
  ca: ["es-ES", "es-MX", "en"], // Catalan
  en: ["en-US"],
  default: ["en"],
};

const SUPPORTED_SPANISH = ["es-MX", "es-ES"];

export function mapLocaleToI18nLocale(locale: string) {
  if (locale === "en-US") {
    // en-US and en are actually the same, because the source of the translations ("en")
    // is written by people in the US. Rewrite requests from "en-US" to "en" so we don't
    // fetch two bundles with the same data. Also because test environments only know how
    // to serve "en".
    return "en";
  }

  if ((locale === "es" || locale.startsWith("es-")) && !SUPPORTED_SPANISH.includes(locale)) {
    return SUPPORTED_SPANISH[0];
  }

  return locale;
}

// for all options read: https://www.i18next.com/overview/configuration-options
const i18nOptions: InitOptions = {
  defaultNS: "dojo.common",
  fallbackLng: FALLBACKS,

  debug: false,
  saveMissing: false,
  saveMissingPlurals: false,

  backend: { backend: Backend },
  updateMissing: false,

  // backend: Backend,
  react: { useSuspense: true },
  interpolation: {
    prefix: "__",
    suffix: "__",
    escapeValue: false, // not needed for react as it escapes by default
  },
};

let namespaces = new Set<string>();

function addNamespaces(ns?: string | readonly string[]) {
  if (typeof ns === "string") namespaces.add(ns);
  else if (Array.isArray(ns)) ns.forEach((n) => namespaces.add(n));
}

export function initialize(options: InitOptions) {
  addNamespaces(options.ns);
  addNamespaces(i18nOptions.ns);

  return i18n.init({
    lng: mapLocaleToI18nLocale(navigator.language) || "en",
    ...i18nOptions,
    ...options,
    backend: {
      ...i18nOptions.backend,
      ...options.backend,
    },
    ns: [...namespaces.keys()],
  });
}

export function loadNamespaces(ns: string | readonly string[]) {
  addNamespaces(ns);
  return i18n.loadNamespaces(ns);
}
