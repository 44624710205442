import type { FC } from "react";
import { DDSBodyText, DDSButton, DDSHeadlineText } from "@web-monorepo/dds";
import { ErrorMessage, Mojo, OTCInput, ResendCodeButton } from "./components";

import imgSrc from "./letter.png";
import styles from "./VerificationPage.module.css";
import { useViewModel } from "./VerificationPage.vm";

export type Props = {
  error: unknown;
  onCode(code: string): void;
  entityType: "teacher" | "parent";
  // needed for context
  // eslint-disable-next-line react/no-unused-prop-types
  onCancel: () => void;
  fullscreen?: boolean;
};

const instructionsId = "one_time_code.verification.label.id";

const VerificationPage: FC<Props> = ({ entityType, error, onCode, fullscreen }) => {
  const vm = useViewModel({ entityType, onCode });
  return (
    <>
      {fullscreen && (
        <nav className={styles.nav}>
          <Mojo className={styles.mojo} />
        </nav>
      )}
      <div className={styles.content}>
        <img src={imgSrc} alt="email icon" />
        <DDSHeadlineText as="h1" className={styles.headline} id={instructionsId}>
          Enter the 6-digit code
        </DDSHeadlineText>
        {vm.instructions && (
          <DDSBodyText as="span" className={styles.instructions}>
            {vm.instructions}
          </DDSBodyText>
        )}
        <form className={styles.form} onSubmit={vm.handleSubmit}>
          <OTCInput formError={error} labeledBy={instructionsId} onChange={vm.handleChange} />
          <ErrorMessage for={error} className={styles.error} />

          <DDSButton
            data-name="otp_entry.verify_code"
            variant="primary"
            size="large"
            type="submit"
            disabled={!vm.canSubmit}
            width="fit"
          >
            Verify
          </DDSButton>

          {/* TODO: calling onCode with an empty string should be practically the same as firing the original request (which should re-trigger an email).  */}
          <ResendCodeButton onClick={() => onCode("")} />
        </form>
      </div>
    </>
  );
};

export default VerificationPage;
