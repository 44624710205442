import { getHumanReadableOutput, makeEmptyQuietHours } from "@classdojo/quiet-hours";
import type { HumanReadableQuietHours } from "@classdojo/quiet-hours";
import { MemberFetcherReturnType } from "@web-monorepo/shared/api/apiTypesHelper";
import { makeMemberQuery, makeApiMutation } from "@web-monorepo/shared/reactQuery";
import { useState, useCallback } from "react";
import { useClassroomFetcher } from "app/pods/classroom";
import {
  useSchoolwideBehaviorsReportFetcher,
  useSchoolwidePointsDashboardFetcher,
  useSchoolwidePointsPerClassFetcher,
  useSchoolwideStudentsReportFetcher,
} from "#/src/pages/(sidebar)/schools/[schoolId]/points/_api/fetchers";

type UserConfigExtended<T> = Omit<UserConfigResponse, "metaData"> & {
  metaData: {
    [key: string]: T;
  };
};
const isUserConfigWithMetaData = <T>(cfg: UserConfigResponse | undefined): cfg is UserConfigExtended<T> => {
  if (cfg?.metaData) {
    return true;
  }
  return false;
};
export const getMetadataValue = <T>(userConfig?: UserConfigResponse, key?: string): T | undefined => {
  const tempUserConfig = isUserConfigWithMetaData<T>(userConfig) ? userConfig : undefined;
  return tempUserConfig && key ? tempUserConfig?.metaData?.[key] : undefined;
};

export const getHumanReadableQuietHours = (userConfig: UserConfigResponse): HumanReadableQuietHours => {
  const quietHours = userConfig?.notificationSettings?.quietHours;
  return getHumanReadableOutput(quietHours || makeEmptyQuietHours());
};

export const useUserConfigFetcher = makeMemberQuery({
  path: `/api/userConfig`,
  fetcherName: "userConfig",
  query: {
    featureFlags: "false",
    experiments: "false",
  },
});

export type UserConfigResponse = MemberFetcherReturnType<typeof useUserConfigFetcher>;

export const useSaveMetadataOperation = makeApiMutation({
  name: "saveMetadata",
  path: "/api/userConfig/metadata/{metaDataName}",
  method: "put",
  onSuccess: () => {
    useClassroomFetcher.invalidateQueries();
    useUserConfigFetcher.invalidateQueries();
    useSchoolwidePointsDashboardFetcher.invalidateQueries();
    useSchoolwideBehaviorsReportFetcher.invalidateQueries();
    useSchoolwideStudentsReportFetcher.invalidateQueries();
    useSchoolwidePointsPerClassFetcher.invalidateQueries();
  },
  onMutate: (params) => {
    useUserConfigFetcher.setQueriesData((draft) => {
      draft.metaData = draft.metaData || {};
      draft.metaData[params.path.metaDataName] = params.body.value;
    });
  },
});

export const useSetNotificationQuietHoursOperation = makeApiMutation({
  name: "setNotificationQuietHours",
  path: "/api/notificationSettings",
  method: "post",
  onMutate: (params) => {
    useUserConfigFetcher.setQueriesData((draft) => {
      if (!draft.notificationSettings) {
        draft.notificationSettings = {};
      }
      draft.notificationSettings.quietHours = params.body.quietHours;
    });
  },
});

// JUST FOR TESTING/DEVELOPMENT
export const setMetadata = (key: string, value: unknown) => {
  useUserConfigFetcher.setQueriesData((draft) => {
    draft.metaData = draft.metaData || {};
    draft.metaData[key] = value;
  });
};

export const useCurrentCountryFetcher = makeMemberQuery({
  path: `/api/country`,
  fetcherName: "country",
  dontThrowOnStatusCodes: [404],
});

export const useGetSetMetadata = <T>(key: string): [T | undefined, (t: T) => void] => {
  const { data: userConfig } = useUserConfigFetcher({});
  const originalValue: T | undefined = getMetadataValue(userConfig, key);
  const { mutate: saveMetadata } = useSaveMetadataOperation();
  const [value, setState] = useState<T | undefined>(originalValue);

  const set = useCallback(
    (value: T) => {
      saveMetadata({ path: { metaDataName: key }, body: { value } });
      setState(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saveMetadata, setState, key],
  );

  return [value, set];
};
