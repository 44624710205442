import { APIResponseError } from "@web-monorepo/infra/responseHandlers";
import { useCallback, useMemo, type MutableRefObject } from "react";
import { NOOP } from "../../reactQuery";
import { MessageThread, AugmentedMessageThread, AugmentedBulletinThread } from "../types";
import { useMessageThreadFetcher } from "./fetchers";
import useWatch from "@classdojo/web/hooks/useWatch";
import { FetchNextPageOptions } from "@tanstack/react-query";
import { useArchiveThreadOperation } from "./useArchiveThreadOperation";
import { sortThreadsAndBulletinsUtil } from "./util";
import type { components } from "@classdojo/ts-api-types";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";

export const ENABLED_THREAD_TYPES = ["a", "b", "c", "d", "e", "f", "g", "h", "t", "i"];

export type SearchVm = {
  searchResultThreads: AugmentedMessageThread[] | undefined;
  loadingSearchResultThreads: boolean;
  loadMoreSearchResultThreads: ((options?: FetchNextPageOptions) => void) | undefined;
  hasMoreSearchResultThreads: boolean | undefined;
};

export type ArchivedThreadsVm = {
  archivedThreads: AugmentedMessageThread[] | undefined;
  loadingArchivedThreads: boolean;
  loadMoreArchivedThreads: ((options?: FetchNextPageOptions) => void) | undefined;
  hasMoreArchivedThreads: boolean | undefined;
  archiveThreadMutation: ReturnType<typeof useArchiveThreadOperation>["mutate"];
};

type ThreadFetcherResult = {
  data?: MessageThread[];
  isFetching: boolean;
  fetchNextPage: (options?: FetchNextPageOptions) => void;
  hasNextPage?: boolean;
};

type BulletinThreadsFetcherResult = {
  data?: components["schemas"]["BulletinThreadCollectionResponseObject"]["_items"];
  isFetching: boolean;
};

export enum ErrorType {
  ForbiddenThread = "ForbiddenThread",
}

export const useThreads = ({
  messageThreadId,
  fetcherResult,
  searchFetcherResult,
  accessibleThreadTypes = ENABLED_THREAD_TYPES,
  onErrorCallback,
  disableInfiniteFetch,
  showClassChatWelcomeMessage,
  archivedThreadsFetcherResult,
  bulletinThreadsFetcherResult,
}: {
  messageThreadId?: string;
  fetcherResult: ThreadFetcherResult;
  searchFetcherResult?: ThreadFetcherResult;
  onErrorCallback?: Record<ErrorType, () => void>;
  accessibleThreadTypes?: string[];
  disableInfiniteFetch?: boolean;
  showClassChatWelcomeMessage?: MutableRefObject<boolean>;
  archivedThreadsFetcherResult?: ThreadFetcherResult;
  bulletinThreadsFetcherResult?: BulletinThreadsFetcherResult;
}) => {
  const {
    data: threads,
    isFetching: loadingThreads,
    fetchNextPage: loadMoreThreads,
    hasNextPage: hasMoreThreads,
  } = fetcherResult;

  let withArchivedThreads: AugmentedMessageThread[] | undefined = undefined;
  let loadingWithArchivedThreads: boolean = false;
  let loadMoreWithArchivedThreads: ((options?: FetchNextPageOptions) => void) | undefined = undefined;
  let hasMoreWithArchivedThreads: boolean | undefined = undefined;

  let archivedThreads: AugmentedMessageThread[] | undefined = undefined;
  let loadingArchivedThreads: boolean = false;
  let loadMoreArchivedThreads: ((options?: FetchNextPageOptions) => void) | undefined = undefined;
  let hasMoreArchivedThreads: boolean | undefined = undefined;

  let augmentedBulletinThreads: AugmentedBulletinThread[] = [];
  let loadingBulletinThreads: boolean = false;

  if (bulletinThreadsFetcherResult?.data) {
    for (const thread of bulletinThreadsFetcherResult.data) {
      (thread as AugmentedBulletinThread).participants = [];
    }
    augmentedBulletinThreads = bulletinThreadsFetcherResult.data as AugmentedBulletinThread[];
    loadingBulletinThreads = bulletinThreadsFetcherResult.isFetching;
  }

  const sortThreadsAndBulletins = (
    threads: AugmentedMessageThread[],
    bulletins: AugmentedBulletinThread[],
  ): (AugmentedMessageThread | AugmentedBulletinThread)[] => {
    return sortThreadsAndBulletinsUtil(threads, bulletins, !hasMoreThreads);
  };

  // need filtered participants with permission none and show deleted users
  // filter out threads based on thread type feature switch
  const augmentedThreads = useMemo(() => {
    if (!threads) {
      return;
    }

    const augmented: AugmentedMessageThread[] = [];
    for (const thread of threads) {
      if (accessibleThreadTypes.includes(thread.type)) {
        const t = {
          ...thread,
          activeParticipants: thread.participants.filter((p) => p.deleted || p.permission !== "none"),
        };

        if (showClassChatWelcomeMessage?.current && thread.type === "e") {
          t.formattedLastMessage = autoTranslate("Welcome to the group");
          t.unreadCount = 1;
          showClassChatWelcomeMessage.current = false;
        }

        augmented.push(t);
      }
    }

    return augmented;
  }, [accessibleThreadTypes, showClassChatWelcomeMessage, threads]);

  const selectedThreadFromAugmentedThreads = useMemo(
    () => augmentedThreads?.find((t) => t._id === messageThreadId),
    [augmentedThreads, messageThreadId],
  );

  const augmentedThreadsWithArchived = useMemo(() => {
    if (!searchFetcherResult?.data) {
      return;
    }

    const augmented: AugmentedMessageThread[] = [];
    for (const thread of searchFetcherResult.data) {
      if (accessibleThreadTypes.includes(thread.type)) {
        const t = {
          ...thread,
          activeParticipants: thread.participants.filter((p) => p.deleted || p.permission !== "none"),
        };

        if (showClassChatWelcomeMessage?.current && thread.type === "e") {
          t.formattedLastMessage = autoTranslate("Welcome to the group");
          t.unreadCount = 1;
          showClassChatWelcomeMessage.current = false;
        }

        augmented.push(t);
      }
    }

    return augmented;
  }, [accessibleThreadTypes, showClassChatWelcomeMessage, searchFetcherResult]);

  const augmentedArchivedThreads = useMemo(() => {
    if (!archivedThreadsFetcherResult?.data) {
      return;
    }

    const augmented: AugmentedMessageThread[] = [];
    for (const thread of archivedThreadsFetcherResult.data) {
      if (accessibleThreadTypes.includes(thread.type)) {
        const t = {
          ...thread,
          activeParticipants: thread.participants.filter((p) => p.deleted || p.permission !== "none"),
        };

        if (showClassChatWelcomeMessage?.current && thread.type === "e") {
          t.formattedLastMessage = autoTranslate("Welcome to the group");
          t.unreadCount = 1;
          showClassChatWelcomeMessage.current = false;
        }

        augmented.push(t);
      }
    }

    return augmented;
  }, [accessibleThreadTypes, showClassChatWelcomeMessage, archivedThreadsFetcherResult]);

  if (archivedThreadsFetcherResult) {
    archivedThreads = augmentedArchivedThreads;
    loadingArchivedThreads = archivedThreadsFetcherResult.isFetching;
    loadMoreArchivedThreads = archivedThreadsFetcherResult.fetchNextPage;
    hasMoreArchivedThreads = archivedThreadsFetcherResult.hasNextPage;
  }

  if (searchFetcherResult) {
    withArchivedThreads = augmentedThreadsWithArchived;
    loadingWithArchivedThreads = searchFetcherResult.isFetching;
    loadMoreWithArchivedThreads = searchFetcherResult.fetchNextPage;
    hasMoreWithArchivedThreads = searchFetcherResult.hasNextPage;
  }

  // Not using this for selectedThread because it would require the user to wait for the thread to load,
  // but we already have the thread in the threads list.
  // However, we do want to activate this fetcher so that it triggers actions when the thread is updated.
  const { data: selectedThreadFromLoaded } = useMessageThreadFetcher(messageThreadId ? { messageThreadId } : NOOP, {
    onError: (error) => {
      if (error instanceof APIResponseError) {
        const errorType = error.response?.status === 403 ? ErrorType.ForbiddenThread : undefined;
        if (errorType && onErrorCallback) {
          onErrorCallback[errorType]?.();
        }
      }
    },
  });

  // Using Object.assign to keep the same reference to the object so that the component does not re-render.
  const augmentedSelectedThreadFromLoaded = useMemo(
    () =>
      selectedThreadFromLoaded &&
      Object.assign(selectedThreadFromLoaded, {
        activeParticipants: selectedThreadFromLoaded.participants.filter((p) => p.deleted || p.permission !== "none"),
      }),
    [selectedThreadFromLoaded],
  );

  // We do this dance here so that the user does not need to wait for the thread to load, but also eventually sees
  // the most up to date version of the thread.
  const selectedThread = useMemo(
    () => augmentedSelectedThreadFromLoaded || selectedThreadFromAugmentedThreads,
    [augmentedSelectedThreadFromLoaded, selectedThreadFromAugmentedThreads],
  );

  const loadMoreThreadsIfAvailable = useCallback(() => {
    if (hasMoreThreads && !loadingThreads) {
      loadMoreThreads({ cancelRefetch: false });
    }
  }, [hasMoreThreads, loadMoreThreads, loadingThreads]);

  const loadMoreThreadsWithArchivedIfAvailable = useCallback(() => {
    if (hasMoreWithArchivedThreads && !loadingWithArchivedThreads && loadMoreWithArchivedThreads) {
      loadMoreWithArchivedThreads({ cancelRefetch: false });
    }
  }, [hasMoreWithArchivedThreads, loadMoreWithArchivedThreads, loadingWithArchivedThreads]);

  useWatch([hasMoreThreads, loadMoreThreads, loadingThreads], () => {
    if (!disableInfiniteFetch) {
      loadMoreThreadsIfAvailable();
    }
  });

  const searchVm: SearchVm = {
    searchResultThreads: withArchivedThreads,
    loadingSearchResultThreads: loadingWithArchivedThreads,
    loadMoreSearchResultThreads: loadMoreThreadsWithArchivedIfAvailable,
    hasMoreSearchResultThreads: hasMoreWithArchivedThreads,
  };

  const archiveThreadMutation = useArchiveThreadOperation().mutate;

  const archivedThreadsVm: ArchivedThreadsVm = {
    archivedThreads,
    loadingArchivedThreads,
    loadMoreArchivedThreads,
    hasMoreArchivedThreads,
    archiveThreadMutation,
  };

  return {
    loadingThreads: !augmentedThreads && loadingThreads,
    selectedThread,
    threads: augmentedThreads,
    loadMoreThreads: loadMoreThreadsIfAvailable,
    isLoadingMoreThreads: loadingThreads,
    hasMoreThreads,
    searchVm,
    archivedThreadsVm,
    bulletinThreads: augmentedBulletinThreads,
    sortThreadsAndBulletins,
    loadingBulletinThreads,
  };
};
