import { makeMemberQuery, makeApiMutation } from "../reactQuery";

const useUserConfigFetcher = makeMemberQuery({
  fetcherName: "sharedUserConfig",
  path: `/api/userConfig`,
  query: {
    featureFlags: "false",
    experiments: "false",
  },
});

const useSaveMetadataOperation = makeApiMutation({
  name: "sharedSaveMetadata",
  path: "/api/userConfig/metadata/{metaDataName}",
  method: "put",
  onMutate: (params) => {
    useUserConfigFetcher.setQueriesData((draft) => {
      draft.metaData = draft.metaData || {};
      draft.metaData[params.path.metaDataName] = params.body.value;
    });
  },
  onSuccess: () => {
    useUserConfigFetcher.invalidateQueries();
  },
});

export function useMetadataKey<T>({ key, parse }: { key: string; parse: (val: unknown) => T }) {
  const { data: userConfig, isLoading } = useUserConfigFetcher({});
  const { mutate: saveMetadata } = useSaveMetadataOperation();

  if (isLoading) {
    return { isLoading: true as const };
  }

  const value = parse(userConfig?.metaData?.[key]);
  const setValue = (value: T) => saveMetadata({ path: { metaDataName: key }, body: { value } });

  return {
    isLoading: false as const,
    value,
    setValue,
  };
}
