import callApi, { CallApiDefaultResponse } from "@web-monorepo/infra/callApi";
import { CollectionFetcherReturnType } from "@web-monorepo/shared/api/apiTypesHelper";
import { invalidateClassQueries, useSchoolAllClassesFetcher } from "@web-monorepo/shared/classroom";
import {
  makeCollectionQuery,
  makeMemberQuery,
  makeApiMutation,
  makeMutation,
  NOOP,
} from "@web-monorepo/shared/reactQuery";
import { useLocation, useSearchParams } from "react-router-dom";
import { INVITE_SCHOOL_LEADER_MODAL_DISMISS_DATE, HAS_CONFIRMED_EMAIL } from "app/data/userConfigKeys";
import { useSchoolClassesFetcher } from "app/pods/classDirectory";
import { Classroom, useClassroomFetcher } from "app/pods/classroom";
import { useSchoolFetcher } from "app/pods/school";
import { useFetchedTeacher, useFetchedTeacherSelectedSchool, useSchoolIdParams } from "app/pods/teacher";
import { getMetadataValue, useUserConfigFetcher } from "app/pods/userConfig";
import { isSchoolLeader, isVerifiedSchoolAdmin } from "app/utils/school";
import { isAfter, subDays, parse } from "@web-monorepo/dates";
import { useShouldShowSchoolData } from "#/src/lib/useShouldShowSchoolData";
import { usePendingTeachers } from "./queries";
import { useSchoolDirectoryStudentsFetcher } from "#/app/pods/schoolStudents";

export const useDirectoryBadgeCount = () => {
  const selectedSchool = useFetchedTeacherSelectedSchool();
  const teacher = useFetchedTeacher();
  const teacherIsVerified = selectedSchool.isVerified;
  const teacherIsAppropriatelyAuthenticated = useShouldShowSchoolData();
  const schoolIdParams = useSchoolIdParams();
  const { data: pendingTeachers = [] } = usePendingTeachers(
    teacherIsVerified &&
      teacherIsAppropriatelyAuthenticated &&
      selectedSchool.schoolId &&
      isVerifiedSchoolAdmin(teacher)
      ? schoolIdParams
      : NOOP,
  );

  return pendingTeachers.length;
};

export const useSchoolSuggestedTeacherInvitesFetcher = makeCollectionQuery({
  fetcherName: "schoolSuggestedTeacherInvites",
  path: "/api/school/{schoolId}/suggestedTeacherInvites",
});

export type SuggestedTeacherInvite = CollectionFetcherReturnType<typeof useSchoolSuggestedTeacherInvitesFetcher>;

export const useSchoolSuggestedTeacherSentInvitesFetcher = makeCollectionQuery({
  fetcherName: "schoolSuggestedTeacherSentInvites",
  path: "/api/communityInvitation",
  query: { status: "all", campaign: "mdrSuggestedTeacherInvites" },
  queryParams: ["targetId"],
});

export const useIgnoreSuggestedInvitationOperation = makeApiMutation({
  name: "ignoreSuggestedInvitation",
  path: "/api/school/{schoolId}/suggestedTeacherInvites/{emailAddress}",
  method: "delete",
  onSuccess: () => {
    useSchoolSuggestedTeacherInvitesFetcher.invalidateQueries();
  },
});

type CoTeacher = {
  collaboratorHasAccount: boolean;
  emailAddress: string;
  isMe: boolean;
  sharingStatus: "accepted";
  teacherId: string;
};

type AddCoTeacherOperationPayload = {
  schoolId: string;
  collaborator: CoTeacher;
  classroomId: string;
};

export const useBulkAddCoTeacherOperation = makeApiMutation({
  name: "bulkAddCoTeacher",
  path: "/api/dojoSchool/{schoolId}/bulk-add-coteacher-to-class",
  method: "post",
  onSuccess: (_data, params) => {
    params.body.classIds.forEach((classroomId) => {
      useClassroomFetcher.invalidateQueries({ id: classroomId });
    });
    invalidateClassQueries();
  },
});

export const useBulkArchiveClassesOperation = makeApiMutation({
  name: "bulkArchiveClasses",
  path: "/api/dojoSchool/{schoolId}/archive-classes",
  method: "put",
  onSuccess: (_data, params) => {
    params.body.classIds.forEach((classroomId) => {
      useClassroomFetcher.invalidateQueries({ id: classroomId });
    });
    invalidateClassQueries();
  },
});

export const useBulkRemoveStudentsOperation = makeApiMutation({
  name: "bulkRemoveStudents",
  path: "/api/dojoSchool/{schoolId}/student",
  method: "delete",
  onSuccess: () => {
    useSchoolDirectoryStudentsFetcher.invalidateQueries();
  },
});

export const useAddCoTeacherOperation = makeMutation<AddCoTeacherOperationPayload, void>({
  name: "addCoTeacher",
  fn: async ({ schoolId, collaborator, classroomId }) => {
    await callApi({
      path: `/api/dojoSchool/${schoolId}/addTeacherToClass`,
      body: { classId: classroomId, teacherId: collaborator.teacherId },
      method: "POST",
    });
  },
  onMutate: (params) => {
    useSchoolClassesFetcher.setQueriesData((draft) => {
      const classroomToUpdate = draft.find((classroom: Classroom) => classroom._id === params.classroomId);
      if (classroomToUpdate) {
        const collaborator = {
          ...params.collaborator,
          _id: "",
          title: "",
          firstName: "",
          lastName: "",
        };
        classroomToUpdate.collaborators = (
          classroomToUpdate.collaborators ? classroomToUpdate.collaborators : []
        ).concat(collaborator);
      }
    });
  },
  onSuccess: (_data, params) => {
    useClassroomFetcher.invalidateQueries({ id: params.classroomId });
    invalidateClassQueries();
    useSchoolAllClassesFetcher.invalidateQueries();
  },
});

export const useTransferClassOperation = makeApiMutation({
  name: "transferClass",
  path: "/api/dojoSchool/{schoolId}/transferClass",
  method: "post",
  onSuccess: (_data, _params) => {
    invalidateClassQueries();
  },
});

type TransferClassesAndRemoveTeacherOperationPayload = {
  schoolId: string;
  teacherId: string;
  classIds: string[];
  ownerId: string;
  ownerEmail?: string;
  removalContext: string;
};

export const useTransferClassesAndRemoveTeacherOperation = makeMutation<
  TransferClassesAndRemoveTeacherOperationPayload,
  CallApiDefaultResponse | void
>({
  name: "transferClassesAndRemoveTeacher",
  fn: async ({ schoolId, teacherId, classIds, ownerId, ownerEmail, removalContext }) => {
    await callApi({
      path: `/api/dojoSchool/${schoolId}/transferClass`,
      body: { classIds, ownerId, ownerEmail },
      method: "POST",
    });

    try {
      await callApi({
        method: "DELETE",
        path: `/api/dojoSchool/${schoolId}/teacher/${teacherId}`,
        query: { context: removalContext },
      });
    } catch (ex: any) {
      if (ex?.response?.status === 404) {
        return;
      }
      throw ex;
    }
  },
  onMutate: (params) => {
    useSchoolFetcher.setQueriesData(
      (draft) => {
        const { teacherId } = params;
        draft.schoolTeachers = (draft.schoolTeachers || []).filter((i) => i._id !== teacherId);
      },
      { id: params.schoolId },
    );
  },
  onSuccess: (_data, _params) => {
    invalidateClassQueries();
    useSchoolAllClassesFetcher.invalidateQueries();
  },
});

export const useSchoolLinkInvitationFetcher = makeMemberQuery({
  fetcherName: "schoolLinkInvitation",
  path: "/api/schoolLinkInvitation",
});

export const useIsEligibleForInviteSchoolLeaderModal = () => {
  const location = useLocation();
  const teacher = useFetchedTeacher();
  const { data: userConfig } = useUserConfigFetcher({});
  const previouslyDismissedDate = getMetadataValue<string>(userConfig, INVITE_SCHOOL_LEADER_MODAL_DISMISS_DATE);
  const { schoolId } = teacher;
  const { data: school } = useSchoolFetcher(schoolId ? { id: schoolId } : NOOP);
  const activeSchoolLeaders = (school?.schoolTeachers || []).filter((schoolTeacher) => {
    return (
      isSchoolLeader(schoolTeacher) &&
      schoolTeacher.isVerified &&
      isAfter(parse(schoolTeacher.lastAccess), subDays(new Date(), 90))
    );
  });
  const isEligiblePage = location.pathname.includes("/directory/");
  const isTeacherNew = isAfter(parse(teacher.joined), subDays(new Date(), 90));

  if (previouslyDismissedDate) return false;
  if (!teacher.schoolVerified || !teacher.emailVerified) return false;
  if (activeSchoolLeaders.length) return false;
  if (!isEligiblePage) return false;
  if (isTeacherNew) return false;

  return true;
};

export const useShowEmailConfirmModal = () => {
  const teacher = useFetchedTeacher();
  const { data: userConfig } = useUserConfigFetcher({});
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const isAccountSettingsOpen = searchParams.has("settingsTab");
  const teacherMetadata = userConfig?.metaData;
  const needsToVerifyEmail = teacher.emailVerified === false;
  const isNewAccount = parse(teacher.joined) > subDays(new Date(), 7);
  const isOnMesagingPage = (location.pathname + location.hash).indexOf("messaging") >= 0;
  let isDismissed = true;
  if (
    !teacherMetadata ||
    // key not in UserMetaData
    (teacherMetadata && !(HAS_CONFIRMED_EMAIL in teacherMetadata)) ||
    // key is in UserMetaData, but value is undefined
    (teacherMetadata && HAS_CONFIRMED_EMAIL in teacherMetadata && teacherMetadata[HAS_CONFIRMED_EMAIL] == undefined)
  ) {
    isDismissed = false;
  }
  const displayConditions =
    !isOnMesagingPage && needsToVerifyEmail && !isNewAccount && !isDismissed && !isAccountSettingsOpen;
  return displayConditions;
};
