import EventEmitter from "events";
import { Adapter, Message, MessageHandler } from "./types";

enum Protocol {
  HAND,
  SHAKE,
}

export default class IframeAdapter implements Adapter {
  static HANDSHAKE_INTERVAL = 500;

  private _sourceWindow: Window;
  private _targetWindow: Window;
  private _targetOrigin: string | undefined;
  private _emitter: EventEmitter;
  private _handshake: boolean;
  private _handshakeTimedOut: boolean;
  private _handshakeAttempts: number;

  // used for testing only
  private _mock: boolean;

  private _handshaking: ReturnType<typeof setInterval>;

  constructor(sourceWindow: Window, targetWindow: Window, targetOrigin?: string, mock?: boolean) {
    this._sourceWindow = sourceWindow;
    this._targetWindow = targetWindow;
    this._targetOrigin = targetOrigin;
    this._emitter = new EventEmitter();
    this._handshake = false;

    this._handshakeTimedOut = false;

    // this is only relevant for testing
    this._mock = mock || false;

    this._handshakeAttempts = 0;
    this._handshaking = setInterval(() => {
      if (this._handshakeAttempts > 40) {
        this._handshakeTimedOut = true;
        clearInterval(this._handshaking);
      } else {
        this._directPublish({ protocol: Protocol.HAND });
        this._handshakeAttempts++;
      }
    }, IframeAdapter.HANDSHAKE_INTERVAL);

    this._sourceWindow.addEventListener("message", (event: MessageEvent) => {
      if (/classdojo\.(dev|test|com)/.test(event.origin) && event.source === this._targetWindow) {
        const { channel, message, protocol } = event.data;
        if (protocol != null) {
          switch (protocol) {
            case Protocol.HAND:
              this._directPublish({ protocol: Protocol.SHAKE });
              break;
            case Protocol.SHAKE:
              clearInterval(this._handshaking);
              this._handshake = true;
              break;
            default:
              throw new Error(`Unknown protocol message received: ${protocol}`);
          }
        } else {
          this._emitter.emit(channel, message);
        }
      }
    });
  }

  private _directPublish(data: Record<string, unknown>): void {
    if (this._mock) {
      // this is only used for tests therefore the type casting
      this._targetWindow.postMessage(data, this._targetOrigin || "*", [this._sourceWindow as unknown as Transferable]);
    } else {
      // some browsers (e.g. Safari), dislike "*" as the targetOrigin, so provide actual targetOrigin
      // when possible
      this._targetWindow.postMessage(data, this._targetOrigin || "*");
    }
  }

  publish(channel: string, message: Message) {
    if (this._handshake) {
      this._directPublish({ channel, message });
      return Promise.resolve();
    }
    return new Promise<void>((resolve, reject) => {
      const check = setInterval(() => {
        if (this._handshake) {
          clearInterval(check);
          this._directPublish({ channel, message });
          return resolve();
        }
        if (this._handshakeTimedOut) {
          clearInterval(check);

          return reject(
            new IframeError(
              `Iframe adapter timedout on hand shake. Attempting to send ${JSON.stringify(
                message,
              )}.\n\nChannel: ${channel}\n\nTarget Origin: ${this._targetOrigin}`,
              this._targetOrigin,
            ),
          );
        }
      }, IframeAdapter.HANDSHAKE_INTERVAL);
    });
  }

  subscribe(channel: string, onMessage: MessageHandler) {
    if (!this._emitter.listeners(channel).includes(onMessage)) {
      this._emitter.addListener(channel, onMessage);
    }
  }

  unsubscribe(channel: string, onMessage: MessageHandler) {
    this._emitter.removeListener(channel, onMessage);
  }

  close() {
    this._emitter.removeAllListeners();
  }
}

export class IframeError extends Error {
  public targetOrigin?: string;
  constructor(message: string, targetOrigin?: string) {
    super(message);
    this.targetOrigin = targetOrigin;
  }
}
