// todo, refactor into single simple hook
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";

const STATE_KEY = "leftNav";

type LeftNavState = {
  sidebarExpanded: boolean;
};

const initialState: LeftNavState = {
  sidebarExpanded: true,
};

// actions
const setSidebarExpandedActionType = "LeftNav.setSidebarExpanded";

// action creators

export const setSidebarExpanded = (sidebarExpanded: boolean) => ({
  sidebarExpanded,
  type: setSidebarExpandedActionType,
});

// selector
export const selectSidebarExpanded = (state: { leftNav: LeftNavState }) => {
  return state[STATE_KEY].sidebarExpanded;
};

const podReducer = (
  state = initialState,
  action: {
    type: "LeftNav.setSidebarExpanded";
    sidebarExpanded?: boolean;
    payload: { schoolId: string };
  },
) => {
  if (action.type === setSidebarExpandedActionType) {
    return { ...state, sidebarExpanded: action.sidebarExpanded };
  }
  return state;
};

const install: PodInstallFunction = (installReducer) => {
  // @ts-expect-error Property 'payload' is missing in type 'AnyAction' but required in type '{ type: "LeftNav.setSidebarExpanded"; sidebarExpanded?: boolean | undefined; payload: { schoolId: string; }; }'.
  installReducer(STATE_KEY, podReducer);
};

export default install;
