import sumBy from "lodash/sumBy";
import { useAllClassroomFetcher } from "../../classroom";
import { makeCollectionQuery, makeMemberQuery } from "../../reactQuery";
import { MessageThread } from "../types";
import { ENABLED_THREAD_TYPES } from "./useThreads";

export const useMessageThreadFetcher = makeMemberQuery({
  fetcherName: "messageThread",
  path: "/api/message-thread/{messageThreadId}",
  dontThrowOnStatusCodes: [403],
  onSuccess: (data) => {
    useParentMessageThreadsFetcher.setQueriesData((draft) => {
      if (data) {
        for (let i = 0; i < draft.length; i++) {
          if (draft[i]._id === data._id) {
            draft[i] = data;
          }
        }
      }
    });

    useClassMessageThreadsFetcher.setQueriesData((draft) => {
      if (data) {
        for (let i = 0; i < draft.length; i++) {
          if (draft[i]._id === data._id) {
            draft[i] = data;
          }
        }
      }
    });
  },
});

export const useMessagesFetcher = makeCollectionQuery({
  fetcherName: "messages",
  path: "/api/message-thread/{messageThreadId}/message",
  // this fetcher is also throwing when a user is removed from a thread,
  //we are doing the error handling in useThreads
  dontThrowOnStatusCodes: [403],
  variedHeaders: ["Accept-Language"],
});

export const useScheduledMessagesFetcher = makeCollectionQuery({
  fetcherName: "scheduledMessages",
  path: "/api/message-thread/{messageThreadId}/message/scheduled",
  dontThrowOnStatusCodes: [403],
});

export const useMessageThreadAudienceFetcher = makeCollectionQuery({
  fetcherName: "messageThreadAudience",
  path: "/api/message-thread/{messageThreadId}/audience",
});

export const useClassAudienceFetcher = makeMemberQuery({
  fetcherName: "classAudience",
  path: "/api/class/{classId}/audience",
  dontThrowOnStatusCodes: [403],
});

export const useParentAudienceFetcher = makeMemberQuery({
  fetcherName: "parentAudience",
  path: "/api/parent/{parentId}/audience",
});

export const useParentCapabilitiesFetcher = makeMemberQuery({
  fetcherName: "parentCapabilities",
  path: "/api/parent/{parentId}/capabilities",
  refetchInterval: 5 * 60 * 1000, // 5 minutes
});

export const useParentMessageThreadsFetcher = makeCollectionQuery({
  fetcherName: "parentMessageThreads",
  path: "/api/parent/{parentId}/message-thread/page",
  query: { limit: "20" },
  select: (data) =>
    data
      .filter(filterByEnabledThreadTypes)
      .filter((thread, index, self) => self.findIndex((t) => t._id === thread._id) === index),

  refetchInterval: 10 * 60 * 1000, // 10 Minutes
  onSuccess: () => {
    useParentMessageThreadsFetcher.setQueriesData((draft) => {
      return draft
        .filter(filterByEnabledThreadTypes)
        .filter((thread, index, self) => self.findIndex((t) => t._id === thread._id) === index);
    });
  },
  keepPreviousData: true,
});

export const useParentUnreadsFetcher = makeMemberQuery({
  fetcherName: "parentUnreadsFetcher",
  path: "/api/parent/{parentId}/unreads",
  queryParams: ["includeBulletins"],
});

export const useParentMessageThreadsWithArchivedFetcher = makeCollectionQuery({
  fetcherName: "parentMessageThreadsWithArchived",
  path: "/api/parent/{parentId}/message-thread/page",
  query: { limit: "20", includeArchived: "true" },
  select: (data) =>
    data
      .filter(filterByEnabledThreadTypes)
      .filter((thread, index, self) => self.findIndex((t) => t._id === thread._id) === index),

  refetchInterval: 10 * 60 * 1000, // 10 Minutes
  onSuccess: () => {
    useParentMessageThreadsFetcher.setQueriesData((draft) => {
      return draft
        .filter(filterByEnabledThreadTypes)
        .filter((thread, index, self) => self.findIndex((t) => t._id === thread._id) === index);
    });
  },
  keepPreviousData: true,
});

export const useParentArchivedMessageThreadsFetcher = makeCollectionQuery({
  fetcherName: "parentArchivedMessageThreads",
  path: "/api/parent/{parentId}/message-thread/archived",
});

export const useParentBulletinThreadsFetcher = makeCollectionQuery({
  fetcherName: "parentBulletinThreads",
  path: "/api/parent/{parentId}/bulletin-thread",
});

export const useSchoolTeacherUnifiedInboxBulletinThreadsFetcher = makeCollectionQuery({
  fetcherName: "schoolTeacherUnifiedInboxBulletinThreads",
  path: "/api/school/{schoolId}/teacher/{teacherId}/bulletin-thread",
});

export const useParentBulletinMessagesFetcher = makeCollectionQuery({
  fetcherName: "parentBulletinMessages",
  path: "/api/district/{districtId}/parent/{parentId}/bulletin-message",
});

export const useClassMessageThreadsFetcher = makeCollectionQuery({
  fetcherName: "classMessageThreads",
  path: "/api/teacher/{teacherId}/class/{classId}/message-thread",
  select: (data) => {
    return data.filter(filterByEnabledThreadTypes);
  },
  onSuccess: (data, params) => {
    useAllClassroomFetcher.setQueriesData((draft) => {
      const classroom = draft.find((classroom) => classroom._id === params.classId);
      if (classroom) {
        const threads = classroom.teacher !== params.teacherId ? data.filter((thread) => thread.type !== "b") : data;

        const unreadMessageCount = sumBy(threads, "unreadCount");
        classroom.unreadMessageCount = unreadMessageCount;
      }
    });
  },
});

export const useClassMessageThreadsWithArchivedFetcher = makeCollectionQuery({
  fetcherName: "classMessageThreadsWithArchived",
  path: "/api/teacher/{teacherId}/class/{classId}/message-thread",
  query: {
    includeArchived: "true",
  },
  select: (data) => {
    return data.filter(filterByEnabledThreadTypes);
  },
  onSuccess: (data, params) => {
    useAllClassroomFetcher.setQueriesData((draft) => {
      const classroom = draft.find((classroom) => classroom._id === params.classId);
      if (classroom) {
        const threads = classroom.teacher !== params.teacherId ? data.filter((thread) => thread.type !== "b") : data;

        const unreadMessageCount = sumBy(threads, "unreadCount");
        classroom.unreadMessageCount = unreadMessageCount;
      }
    });
  },
});

export const useClassArchivedMessageThreadsFetcher = makeCollectionQuery({
  fetcherName: "classArchivedMessageThreads",
  path: "/api/teacher/{teacherId}/class/{classId}/message-thread/archived",
});

export const useTeacherMessageSettingsFetcher = makeMemberQuery({
  fetcherName: "teacherMessageSettings",
  path: "/api/teacher/{teacherId}/message_settings",
});

export const useParentMessageSettingsFetcher = makeMemberQuery({
  fetcherName: "parentMessageSettings",
  path: "/api/parent/{parentId}/message_settings",
});

export const useMessageFetcher = makeMemberQuery({
  fetcherName: "message",
  path: "/api/message-thread/{messageThreadId}/message/{messageId}",
  onSuccess: (data, params) => {
    // mutate the message in the message thread
    useMessagesFetcher.setQueriesData(
      (draft) => {
        const messageIndex = draft.findIndex((t) => t._id === data._id);
        if (messageIndex > -1) {
          draft[messageIndex] = data;
        }
      },
      { messageThreadId: params.messageThreadId },
    );
  },
  shouldFetchImmediately: false,
});

const filterByEnabledThreadTypes = (thread: MessageThread) => ENABLED_THREAD_TYPES.includes(thread.type);
