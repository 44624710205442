import {
  fromToday as baseFromToday,
  getMonths as baseGetMonths,
  getMonthsShort as baseGetMonthsShort,
  getWeekdays as baseGetWeekdays,
  getWeekdaysShort as baseGetWeekdaysShort,
  makeShortDate as baseMakeShortDate,
} from "@classdojo/web";
import { i18n } from "@web-monorepo/i18next";

function getCurrentLanguage() {
  return i18n.language;
}

export const fromToday = (time: Date) => baseFromToday(time, getCurrentLanguage());
export const makeShortDate = (time: Date) => baseMakeShortDate(time, getCurrentLanguage());
export const getMonths = () => baseGetMonths(getCurrentLanguage());
export const getMonthsShort = () => baseGetMonthsShort(getCurrentLanguage());
export const getWeekdays = () => baseGetWeekdays(getCurrentLanguage());
export const getWeekdaysShort = () => baseGetWeekdaysShort(getCurrentLanguage());
