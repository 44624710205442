import { MinutiaeText } from "@classdojo/web/nessie";
import { RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { useEffect, useMemo, useRef, useState } from "react";
import { ColorStyles } from "@classdojo/web/nessie/components/theme";

export type ActivityTagsListProps = {
  tags: string[];
  standards: string[];
  textStyle: ColorStyles;
};

const minTagWidth = 48;

export const DojoIslandsActivityTagsList = ({ tags, standards, textStyle }: ActivityTagsListProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const activityTags = useMemo(() => {
    const tagsInfo = tags.map((tag?: string) => {
      const tagSuffix = tag?.split(".").pop(); // grab the name of the tag from the end of the translation key
      return {
        title: tag ?? "",
        color: tagSuffix && COLORS[tagSuffix] ? COLORS[tagSuffix] : DEFAULT_COLOR,
        backgroundColor:
          tagSuffix && BACKGROUND_COLORS[tagSuffix] ? BACKGROUND_COLORS[tagSuffix] : DEFAULT_BACKGROUND_COLOR,
      };
    });

    const standardsInfo = Array.from(new Set(standards)).map((standard) => {
      return {
        title: standard,
        color: DEFAULT_COLOR,
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
      };
    });
    return [...tagsInfo, ...standardsInfo];
  }, [tags, standards]);

  const [lastTagWidth, setLastTagWidth] = useState(0);
  const [visibleCount, setVisibleCount] = useState(activityTags.length);
  const [resizeObserver, setResizeObserver] = useState<ResizeObserver | null>(null);

  useEffect(() => {
    if (!containerRef.current) return;
    if (!resizeObserver) {
      const observer = new ResizeObserver(() => {
        // Calculate the number of tags to hide based on the width of the container
        if (!containerRef.current) return;

        const containerWidth = containerRef.current.offsetWidth - 60; // leave space for the "+X more" text
        let totalWidth = 0;
        let count = 0;
        let remainingWidth = 0;
        for (let i = 0; i < containerRef.current.children.length; i++) {
          const child = containerRef.current.children[i] as HTMLDivElement;
          totalWidth += child.offsetWidth + 6; // add 6px for the xs gap between tags
          if (totalWidth >= containerWidth) {
            // Calculate the remaining width in the row in order to render a partially visible tag
            remainingWidth = containerWidth - (totalWidth - child.offsetWidth);
            break;
          }
          count++;
        }

        // bulk ignoring existing errors
        // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
        setVisibleCount(count);
        // bulk ignoring existing errors
        // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
        setLastTagWidth(remainingWidth);
      });
      // bulk ignoring existing errors
      // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
      setResizeObserver(observer);
      observer.observe(containerRef.current);
    }
    return () => resizeObserver?.disconnect();
  }, [resizeObserver]);

  if (activityTags.length === 0) {
    return null;
  }

  let countToRender = visibleCount;
  // if there's space for one last tag, go ahead and render it with ellipsis
  if (lastTagWidth > minTagWidth && visibleCount < activityTags.length) {
    countToRender++;
  }

  const displayedTags = activityTags.slice(0, countToRender);
  const hiddenCount = activityTags.length - countToRender;

  return (
    <div ref={containerRef} sx={containerStyle}>
      {displayedTags.map((tag, i) => {
        const { title, color, backgroundColor } = tag;
        const isTruncatedTag = i === countToRender - 1 && countToRender > visibleCount;

        let tagSx: ThemeUIStyleObject = { ...tagStyle, color, backgroundColor };
        if (isTruncatedTag) {
          tagSx = { ...tagSx, ...truncatedTagStyle, width: isTruncatedTag ? `${lastTagWidth}px` : "auto" };
        }

        return (
          <MinutiaeText key={`tag_${i}`} sx={tagSx}>
            {title}
          </MinutiaeText>
        );
      })}
      {hiddenCount > 0 && (
        <MinutiaeText kind={textStyle} sx={{ fontWeight: "dt_semiBold" }}>
          +{hiddenCount} more
        </MinutiaeText>
      )}
    </div>
  );
};

const DEFAULT_COLOR = RAW_cssValue("#403F6C");
const DEFAULT_BACKGROUND_COLOR = RAW_cssValue("#E2E4F0");

const COLORS: { [key: string]: any } = {
  teamwork: RAW_cssValue("#935202"),
  communication: RAW_cssValue("#935202"),
  creativity: RAW_cssValue("#612BD4"),
  math: RAW_cssValue("#612BD4"),
  empathy: RAW_cssValue("#C34193"),
  stem: RAW_cssValue("#13803A"),
  problemSolving: RAW_cssValue("#1B63C0"),
  conflictResolution: RAW_cssValue("#B9410E"),
  exploration: RAW_cssValue("#0E5A67"),
} as const;

const BACKGROUND_COLORS: { [key: string]: any } = {
  teamwork: RAW_cssValue("#FFF7B2"),
  communication: RAW_cssValue("#FFF7B2"),
  creativity: RAW_cssValue("#F0D9FF"),
  math: RAW_cssValue("#F0D9FF"),
  empathy: RAW_cssValue("#FFE8F7"),
  stem: RAW_cssValue("#C7FFEA"),
  problemSolving: RAW_cssValue("#EBF2FF"),
  conflictResolution: RAW_cssValue("#FFEEDB"),
  exploration: RAW_cssValue("#CCF4FF"),
} as const;

const containerStyle = {
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "center",
  display: "inline-flex",
  gap: "dt_xs",
} as ThemeUIStyleObject;

const tagStyle = {
  paddingY: "dt_xxs",
  paddingX: "dt_xs",
  borderRadius: RAW_cssValue("6px"),
  whiteSpace: "nowrap",
  fontWeight: RAW_cssValue(500),
} as ThemeUIStyleObject;

const truncatedTagStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
} as ThemeUIStyleObject;
