import { components } from "@classdojo/ts-api-types";
import { makeApiMutation, makeCollectionQuery, makeMemberQuery } from "@web-monorepo/shared/reactQuery";

export type DojoIslandsV3Activity = components["schemas"]["MonsterverseActivityDefinition"];

export const useMonsterverseActivitiesFetcher = makeCollectionQuery({
  path: "/api/monsterverse/class/{classId}/activities",
  fetcherName: "monsterverseActivities",
  query: {
    translated: "true",
  },
});

export const useMonsterverseAssignedActivityFetcher = makeMemberQuery({
  path: "/api/monsterverse/class/{classId}/assignedActivity",
  fetcherName: "monsterverseAssignedActivity",
  query: {
    translated: "true",
  },
});

export const useReplaceMonsterverseAssignedActivityOperation = makeApiMutation({
  name: "assignMonsterverseActivity",
  path: "/api/monsterverse/class/{classId}/assignedActivity",
  queryParams: ["translated"],
  method: "put",
  onSuccess: (data, params) => {
    // optimistically update the cache so that the activity metadata is available immediately
    useMonsterverseAssignedActivityFetcher.setQueriesData(
      (draft) => {
        if (!draft) {
          return;
        }
        Object.assign(draft, data.body);
      },
      { classId: params.path.classId },
    );
    return data;
  },
});
