import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";
import csv from "papaparse";
import { Behavior } from "app/pods/behavior";
import { Award, Note, StudentReportCsvDataResponse, WholeClassReportCsvDataResponse } from "app/pods/reports";
import { fullName } from "app/utils/name";
import { makeShortDate } from "app/utils/time";
import { isSame, parse } from "@web-monorepo/dates";
import downloadFile from "#/app/utils/downloadFile";

type BehaviorOrAward = Award | Behavior;
type AttendanceItem = WholeClassReportCsvDataResponse["attendance"][number];
type Attendance = AttendanceItem[];
type Record = AttendanceItem["records"][number];
type RecordState = Record["state"] | "unknown";
type AttendanceStateForStudent = (records: Record[]) => RecordState;
type Student = WholeClassReportCsvDataResponse["students"][number];
type AttendanceByStudent = { [studentId: string]: { [_ in RecordState]+?: number } };

function getAttendanceByStudent(attendance: Attendance) {
  const result: AttendanceByStudent = {};

  const records = attendance.flatMap((a) => a.records);

  for (const record of records) {
    const {
      state,
      student: { _id: studentId },
    } = record;

    if (!result[studentId]) {
      result[studentId] = {};
    }

    result[studentId][state] = (result[studentId][state] ?? 0) + 1;
  }

  return result;
}

function getHeader(positiveBehaviorNames: string[], negativeBehaviorNames: string[], neutralBehaviorNames: string[]) {
  return [
    autoTranslate("Student"),
    "",
    autoTranslate("Present"),
    autoTranslate("Late"),
    autoTranslate("Left early"),
    autoTranslate("Absent"),
    "",
    positiveBehaviorNames,
    "",
    negativeBehaviorNames,
    "",
    autoTranslate("Positive"),
    autoTranslate("Needs work"),
    autoTranslate("Percent positive"),
    "",
    neutralBehaviorNames,
  ].flat();
}

function getRows({
  students,
  positiveBehaviorNames,
  negativeBehaviorNames,
  neutralBehaviorNames,
  attendanceByStudent,
  awards,
}: {
  students: Student[];
  positiveBehaviorNames: string[];
  negativeBehaviorNames: string[];
  neutralBehaviorNames: string[];
  attendanceByStudent: AttendanceByStudent;
  awards: Award[];
}) {
  return students.map((student) =>
    [
      fullName(student),
      "",
      ...attendanceColumns({ student, attendanceByStudent }),
      "",
      ...awardColumns({ positiveBehaviorNames, negativeBehaviorNames, neutralBehaviorNames, awards, student }),
    ].flat(),
  );
}

function awardColumns({
  positiveBehaviorNames,
  negativeBehaviorNames,
  neutralBehaviorNames,
  awards,
  student,
}: {
  positiveBehaviorNames: string[];
  negativeBehaviorNames: string[];
  neutralBehaviorNames: string[];
  awards: Award[];
  student: Student;
}) {
  const awardsByName = groupBy(awards, generateName<Award>);

  const createEntryForBehavior = (name: string) => {
    return awardsByName[name].reduce(function (s, a) {
      if (a.student._id !== student._id) return s;
      return s + Math.abs(a.weight);
    }, 0);
  };

  const createEntryForNeutralBehavior = (name: string) => {
    return awardsByName[name].filter((a) => a.student._id === student._id).length;
  };

  const positiveEntries = positiveBehaviorNames.map<string | number>(createEntryForBehavior);
  const negativeEntries = negativeBehaviorNames.map<string | number>(createEntryForBehavior);

  const neutralEntries = neutralBehaviorNames.map(createEntryForNeutralBehavior);

  const positiveSum = positiveEntries.reduce<number>(function (s, x) {
    if (typeof x !== "number") return s;
    return s + x;
  }, 0);
  const negativeSum = negativeEntries.reduce<number>(function (s, x) {
    if (typeof x !== "number") return s;
    return s + x;
  }, 0);
  return [
    positiveEntries.map(blankForZero).map((x) => x.toString()),
    "",
    negativeEntries.map(blankForZero).map((x) => x.toString()),
    "",
    positiveSum.toString(),
    negativeSum.toString(),
    getPercentPositive(positiveSum, negativeSum),
    "",
    neutralEntries.map(blankForZero).map((x) => x.toString()),
  ];
}

function attendanceColumns({
  student,
  attendanceByStudent,
}: {
  student: Student;
  attendanceByStudent: AttendanceByStudent;
}) {
  return [
    attendanceByStudent[student._id]?.present?.toString() || "",
    attendanceByStudent[student._id]?.tardy?.toString() || "",
    attendanceByStudent[student._id]?.leftEarly?.toString() || "",
    attendanceByStudent[student._id]?.absent?.toString() || "",
  ];
}

function getFilename({
  attendance,
  start,
  end,
  classroom,
}: Pick<WholeClassReportCsvDataResponse, "attendance" | "start" | "end" | "classroom">) {
  const { startStr, endStr } = makeStartAndEndStrings({
    attendance,
    start,
    end,
  });

  return autoTranslate("ClassDojo-Report __classroomName__ __start__ __end__.csv", {
    classroomName: classroom.name,
    start: startStr,
    end: endStr,
  });
}

export function downloadClassroomReportCSV({
  classroom,
  behaviors,
  students,
  attendance,
  awards,
  start,
  end,
}: WholeClassReportCsvDataResponse & { behaviors: Behavior[] }) {
  const { positiveBehaviorNames, negativeBehaviorNames, neutralBehaviorNames } = getAllBehaviorNames(behaviors, awards);

  const attendanceByStudent = getAttendanceByStudent(attendance);
  const header = getHeader(positiveBehaviorNames, negativeBehaviorNames, neutralBehaviorNames);
  const rows = getRows({
    students,
    positiveBehaviorNames,
    negativeBehaviorNames,
    neutralBehaviorNames,
    attendanceByStudent,
    awards,
  });
  // Add a BOM to try to force Excel to recognize UTF-8 in a csv file
  const contents = `\uFEFF${csv.unparse([header].concat(rows).map((row) => row.map(escapeCsvMacro)))}`;

  downloadFile({
    contents,
    type: "text/csv",
    filename: getFilename({ attendance, start, end, classroom }),
  });
}

type CSVRow = [Date, string, RecordState, string, number | string, string, string];

function downloadStudentAwardsReportCSV({
  attendance,
  awards,
  attendanceStateForStudent,
  notes,
}: {
  attendance: Attendance;
  awards: Award[];
  attendanceStateForStudent: AttendanceStateForStudent;
  notes: Note[];
}) {
  const header = ["Date and time", "Teacher", "Attendance state", "Skill name", "Weight", "Award Type", "Note"];

  const formatDate = (date: Date) => date.toLocaleString("en").replace(", ", " ").toLocaleLowerCase();

  const attenanceStateForStudentOnDate = (date: Date) => {
    // TODO: I converted this logic faithfully, but I don't think UTC is the correct time to compare in:
    const result = attendance.find((item) => isSame(parse(item.date), date, { mode: "day", tz: "UTC" }));
    const records = result && result.records ? result.records : [];
    return attendanceStateForStudent(records);
  };

  const awardRows = awards.map<CSVRow>((award) => {
    const awardDate = parse(award.dateAwarded);
    return [
      awardDate,
      award.teacher.name,
      attenanceStateForStudentOnDate(awardDate),
      award.name,
      award.weight,
      award.type,
      "",
    ];
  });

  const noteRows = notes.map<CSVRow>((note) => {
    const noteDate = parse(note.date);

    const teacherName =
      "name" in note.teacher ? note.teacher.name : `${note.teacher.firstName} ${note.teacher.lastName}`;

    return [noteDate, teacherName, attenanceStateForStudentOnDate(noteDate), "", "", "", note.text];
  });

  const rows = sortBy([...awardRows, ...noteRows], (row) => row[0].valueOf()).map((row) => [
    formatDate(row[0]),
    ...row.slice(1),
  ]);

  return { header, rows };
}

export function downloadStudentReportCSV({
  classroom,
  student,
  attendance,
  awards,
  start,
  end,
  notes,
}: StudentReportCsvDataResponse) {
  const attendanceStateForStudent = (records: Record[]) => {
    const record = records.find((r) => r.student._id === student._id);
    return record?.state || "unknown";
  };

  const { header, rows } = downloadStudentAwardsReportCSV({ attendance, awards, attendanceStateForStudent, notes });
  const escapeMacro = escapeCsvMacroButLeaveBeginningDashes;
  const csvRows = [header, ...rows].map((row) =>
    row.map(
      // @ts-expect-error Type 'string | number | Date' is not assignable to type 'string'.
      escapeMacro,
    ),
  );
  // Add a BOM to try to force Excel to recognize UTF-8 in a csv file
  const contents = `\uFEFF${csv.unparse(csvRows)}`;

  const { startStr, endStr } = makeStartAndEndStrings({
    attendance,
    start,
    end,
  });

  const filename = autoTranslate("ClassDojo-Report __classroomName__ __studentName__ __start__ __end__.csv", {
    studentName: fullName(student),
    classroomName: classroom.name,
    start: startStr,
    end: endStr,
  });

  downloadFile({
    contents,
    filename,
    type: "text/csv",
  });
}

function blankForZero(value: string | number) {
  return value || "";
}

function getPoint<T extends { points: number } | { weight: number }>(behaviorOrAward: T) {
  return (
    ("points" in behaviorOrAward && behaviorOrAward.points) ||
    ("weight" in behaviorOrAward && behaviorOrAward.weight) ||
    0
  );
}

function generateName<T extends { name: string; points: number } | { name: string; weight: number }>(
  behaviorOrAward: T,
) {
  return `${behaviorOrAward.name} (${getPoint(behaviorOrAward)})`;
}

function onlyNames(behaviorsOrAwards: BehaviorOrAward[], filterFn: (behaviorOrAward: BehaviorOrAward) => boolean) {
  return behaviorsOrAwards.filter(filterFn).map(generateName);
}

function isPositive(behaviorOrAward: BehaviorOrAward) {
  return behaviorOrAward.positive === true && getPoint(behaviorOrAward) !== 0;
}

function isNeutral(behaviorOrAward: BehaviorOrAward) {
  return getPoint(behaviorOrAward) === 0;
}

function isNegative(behaviorOrAward: BehaviorOrAward) {
  return behaviorOrAward.positive === false && getPoint(behaviorOrAward) !== 0;
}

function getPercentPositive(positiveSum = 0, negativeSum = 0) {
  if (!positiveSum && !negativeSum) {
    return "";
  }

  const percentPositive = (100 * positiveSum) / (positiveSum + negativeSum);

  return `${percentPositive.toFixed(0)}%`;
}

function getAllBehaviorNames(behaviors: Behavior[], awards: Award[]) {
  return {
    positiveBehaviorNames: [...new Set([...onlyNames(awards, isPositive), ...onlyNames(behaviors, isPositive)])],
    negativeBehaviorNames: [...new Set([...onlyNames(awards, isNegative), ...onlyNames(behaviors, isNegative)])],
    neutralBehaviorNames: [...new Set([...onlyNames(awards, isNeutral), ...onlyNames(behaviors, isNeutral)])],
  };
}

function makeStartAndEndStrings({ attendance, start, end }: { attendance: Attendance; start: string; end: string }) {
  if (start && end) {
    return {
      startStr: makeShortDate(parse(start)),
      endStr: makeShortDate(parse(end)),
    };
  }

  // we're in `all time` mode (so we look for earliest attendance and go until now)
  const earliestAttendanceRecord = attendance.find((attendance) => attendance.records && attendance.records.length);

  return {
    startStr: makeShortDate(parse(earliestAttendanceRecord?.date ?? attendance?.[0]?.date)),
    endStr: makeShortDate(new Date()),
  };
}

function escapeCsvMacro(string: string) {
  const mostlyEscapedString = escapeCsvMacroButLeaveBeginningDashes(string);
  return mostlyEscapedString[0] === "-" ? `'${mostlyEscapedString}` : mostlyEscapedString;
}

function escapeCsvMacroButLeaveBeginningDashes(string: string) {
  if (typeof string !== "string") {
    string = String(string);
  }

  if (string[0] === "=" || string[0] === "+") {
    return `'${string}`;
  }

  return string;
}
