import { ReactNode, Suspense, useEffect } from "react";
import { I18nextProvider as $I18nextProvider } from "react-i18next";
import { useTranslation } from ".";
import { emitLocaleChange } from "./events";
import { i18n, loadNamespaces } from "./i18n";
import { overrideLocalLanguage } from "@web-monorepo/dates";

export type I18nextContainerProps = React.PropsWithChildren<{
  locale: string | null;
  namespaces: readonly string[];
  fallback: ReactNode;
  onLocaleChangeSuccess?: () => void;
}>;

/**
 * LoadingBlocker is an interim solution while we support both old-school
 * translate() calls as well as the new automatic translations code.
 * Something needs to prevent the content from displaying while we load
 * translations. In the web implementation, the solution involves a
 * complex series of sagas and redux actions. This simpler implementation
 * uses the useTranslate hook from react-i18next, we'll eventually want to
 * useSuspense: true, and have more suspense-friendly code, but this requires
 * no direct use of the translate() function:
 */
const LoadingBlocker = ({ children, namespaces }: React.PropsWithChildren<{ namespaces: readonly string[] }>) => {
  // This will trigger suspense if any of the core namespaces aren't loaded:
  useTranslation(namespaces);

  return <>{children}</>;
};

export const I18nextContainer = ({
  children,
  locale,
  namespaces,
  fallback,
  onLocaleChangeSuccess,
}: I18nextContainerProps) => {
  useEffect(() => {
    overrideLocalLanguage(locale);
    i18n.changeLanguage(locale || undefined).then(
      () => {
        emitLocaleChange(locale || undefined);
        onLocaleChangeSuccess?.();
      },
      (err: string[]) => {
        // err is an array of strings here. filter down to the locales we actually care about
        err = err.filter((errorStr: string) => !locale || errorStr.includes(locale));
        if (err.length) {
          throw new Error(`Failed to load language resources: ${err.join(", ")}`);
        }
      },
    );
  }, [locale, namespaces]);

  useEffect(() => {
    loadNamespaces(namespaces).then(() => onLocaleChangeSuccess?.());
  }, [namespaces]);

  return (
    <$I18nextProvider i18n={i18n}>
      <Suspense fallback={fallback}>
        <LoadingBlocker namespaces={namespaces}>{children}</LoadingBlocker>
      </Suspense>
    </$I18nextProvider>
  );
};
