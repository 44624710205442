import { UploadedFileInfo } from "@classdojo/web/utils/uppy";
import callApi from "@web-monorepo/infra/callApi";
import {
  APIRequestParameters,
  APIResponse,
  APIRequestBody,
  MemberFetcherReturnType,
  CollectionFetcherReturnType,
} from "@web-monorepo/shared/api/apiTypesHelper";
import { useAllClassroomFetcher } from "@web-monorepo/shared/classroom";
import { makeCollectionQuery, makeMemberQuery, makeApiMutation, makeMutation } from "@web-monorepo/shared/reactQuery";
import uniqueId from "lodash/uniqueId";
import { useSessionFetcher } from "app/pods/auth";
import { TeacherSession } from "app/pods/teacher";

export const LIST_NAME_ALL_POSTS = "allPostsList";
export const LIST_NAME_ASSIGNMENT = "assignmentItemsList";
export const LIST_NAME_STUDENT = "studentItemsList";

export const ITEM_STATE_DRAFT = "saved";

//
// -----------------------------------
// ACTION CREATORS
//

// The only reason why we need classroomId here is because of the interoperability with PortfolioV1
// Which listens on this actions to refetch the 'counts'
// Once we remove the portfoliov1 code we can remove the classroom id as dependency from these
// invalidate calls.
export const invalidateAllPostsCollection = (classroomId: string) => {
  usePortfolioItemFetcher.invalidateQueries({ classId: classroomId });
  usePortfolioCountsSummaryFetcher.invalidateQueries({ classId: classroomId });
  usePortfolioAllPostsFetcher.invalidateQueries({ classId: classroomId });
};
export const invalidateStudentPostsCollection = (classroomId: string) => {
  usePortfolioStudentPostsFetcher.invalidateQueries({ classId: classroomId });
  usePortfolioCountsSummaryFetcher.invalidateQueries({ classId: classroomId });
  usePortfolioItemFetcher.invalidateQueries({ classId: classroomId });
};
export const invalidateAssignmentPostsCollection = (assignmentId: string) => {
  usePortfolioAssignmentPostsFetcher.invalidateQueries({ assignmentId });
  usePortfolioCountsSummaryFetcher.invalidateQueries();
  usePortfolioItemFetcher.invalidateQueries();
};

export type UpdatePortfolioAssignment = Omit<
  APIRequestBody<"/api/portfolioAssignment/{assignmentId}", "put">,
  // this is injected by the container callback.
  "classId"
> & { assignmentId: string };

export type CreatePortfolioAssignment = Omit<APIRequestBody<"/api/portfolioAssignment", "post">, "classId">;

export type PortfolioPopularActivity = APIResponse<"/api/portfolioActivityFromClassDojo", "get">["_items"][number];

export const usePortfolioStudentPostsFetcher = makeCollectionQuery({
  fetcherName: "portfolioStudentPosts",
  path: "/api/dojoClass/{classId}/portfolio",
  query: {
    state: "all",
    includeMultipageWorksheets: "true",
  },
  queryParams: ["studentId"],
});

export const useCreatePortfolioPostOperation = makeApiMutation({
  name: "createPortfolioPost",
  path: "/api/dojoClass/{classId}/storyFeed",
  method: "post",
  onSuccess: () => {
    usePortfolioStudentPostsFetcher.invalidateQueries();
    usePortfolioAssignmentPostsFetcher.invalidateQueries();
    usePortfolioAllPostsFetcher.invalidateQueries();
  },
});

export const usePortfolioPopularActivitiesFetcher = makeCollectionQuery({
  fetcherName: "portfolioPopularActivities",
  path: `/api/portfolioActivityFromClassDojo`,
});

export const hasVideoInstructionsAttachment = (activity: PortfolioPopularActivity) => {
  return activity.attachments && activity.attachments[0].type === "video";
};

export const hasPhotoAttachment = (activity: PortfolioPopularActivity) => {
  return activity.attachments && activity.attachments[0].type === "photo";
};

export const hasWorksheetImage = (activity: PortfolioPopularActivity) => {
  return !!activity.worksheetUrl;
};

export const usePortfolioAssignmentPostsFetcher = makeCollectionQuery({
  fetcherName: "portfolioAssignmentPosts",
  dontThrowOnStatusCodes: [404],
  path: "/api/dojoClass/{classId}/assignment/{assignmentId}/posts",
});

export const usePortfolioAssignmentFetcher = makeMemberQuery({
  path: `/api/portfolioAssignment/{assignmentId}`,
  dontThrowOnStatusCodes: [403, 404],
  fetcherName: "portfolioAssignment",
});

export const useApproveAllPendingAssignmentItemsOperation = makeApiMutation({
  name: "approveAllPendingAssignmentItems",
  path: "/api/portfolioAssignment/{assignmentId}/approveAll",
  method: "post",
  onSuccess: () => {
    usePortfolioAssignmentPostsFetcher.invalidateQueries();
    usePortfolioAssignmentFetcher.invalidateQueries();
    usePortfolioItemFetcher.invalidateQueries();
    usePortfolioAssignmentsFetcher.invalidateQueries();
    usePortfolioCountsSummaryFetcher.invalidateQueries();
  },
});

export const useApproveAllPendingPortfolioItemsOperation = makeApiMutation({
  name: "approveAllPendingPortfolioItems",
  path: "/api/dojoClass/{classId}/portfolio/approveAll",
  method: "post",
  onMutate: (params) => {
    useAllClassroomFetcher.setQueriesData((draft) => {
      const classroomId = params.path?.classId;
      if (!classroomId) return;

      const classroomIndexToUpdate = draft.findIndex((classroom) => classroom._id === classroomId);
      if (classroomIndexToUpdate !== -1) {
        draft[classroomIndexToUpdate] = { ...draft[classroomIndexToUpdate], pendingPostCount: 0 };
      }
    });
  },
  onSuccess: () => {
    usePortfolioAssignmentFetcher.invalidateQueries();
    usePortfolioItemFetcher.invalidateQueries();
    usePortfolioAssignmentsFetcher.invalidateQueries();
    usePortfolioAllPostsFetcher.invalidateQueries();
    usePortfolioCountsSummaryFetcher.invalidateQueries();
  },
});

export const useApprovePendingPortfolioItemOperation = makeApiMutation({
  name: "approvePendingPortfolioItem",
  path: "/api/dojoClass/{classId}/portfolio/{portfolioId}/approve",
  method: "post",
  onMutate: (params) => {
    useAllClassroomFetcher.setQueriesData((draft) => {
      const classroomId = params.path?.classId;
      if (!classroomId) return;

      const classroomIndexToUpdate = draft.findIndex((classroom) => classroom._id === classroomId);
      if (classroomIndexToUpdate !== -1) {
        const currentCount = Number(draft[classroomIndexToUpdate]?.pendingPostCount);
        const pendingPostCount = Math.max(currentCount - 1, 0);
        draft[classroomIndexToUpdate] = { ...draft[classroomIndexToUpdate], pendingPostCount };
      }
    });
  },
  onSuccess: () => {
    usePortfolioStudentPostsFetcher.invalidateQueries();
    usePortfolioAssignmentPostsFetcher.invalidateQueries();
    usePortfolioAssignmentFetcher.invalidateQueries();
    usePortfolioItemFetcher.invalidateQueries();
    usePortfolioAssignmentsFetcher.invalidateQueries();
    usePortfolioAllPostsFetcher.invalidateQueries();
    usePortfolioCountsSummaryFetcher.invalidateQueries();
  },
});

export const useArchiveAssignmentOperation = makeApiMutation({
  name: "archivePortfolioAssignment",
  path: "/api/portfolioAssignment/{assignmentId}/complete",
  method: "post",
  onSuccess: () => {
    usePortfolioAssignmentFetcher.invalidateQueries();
    usePortfolioAssignmentsFetcher.invalidateQueries();
    usePortfolioCountsSummaryFetcher.invalidateQueries();
  },
});

export const useDeletePortfolioAssignmentOperation = makeApiMutation({
  name: "deletePortfolioAssignment",
  path: "/api/portfolioAssignment/{assignmentId}",
  method: "delete",
  catchError: (err) => err,
  onSuccess: () => {
    usePortfolioAssignmentFetcher.invalidateQueries();
    usePortfolioAssignmentsFetcher.invalidateQueries();
    usePortfolioCountsSummaryFetcher.invalidateQueries();
  },
});

export const useDeletePortfolioItemOperation = makeApiMutation({
  name: "deletePortfolioItem",
  path: "/api/dojoClass/{classId}/portfolio/{postId}",
  method: "delete",
  onSuccess: () => {
    usePortfolioStudentPostsFetcher.invalidateQueries();
    usePortfolioAssignmentPostsFetcher.invalidateQueries();
    usePortfolioAssignmentFetcher.invalidateQueries();
    usePortfolioAssignmentsFetcher.invalidateQueries();
    usePortfolioAllPostsFetcher.invalidateQueries();
    usePortfolioCountsSummaryFetcher.invalidateQueries();
  },
});

export const useUnarchiveAssignmentOperation = makeApiMutation({
  name: "unarchivePortfolioAssignment",
  path: "/api/portfolioAssignment/{assignmentId}/uncomplete",
  method: "post",
  onSuccess: () => {
    usePortfolioAssignmentFetcher.invalidateQueries();
    usePortfolioItemFetcher.invalidateQueries();
    usePortfolioAssignmentsFetcher.invalidateQueries();
    usePortfolioCountsSummaryFetcher.invalidateQueries();
  },
});

export const useUnsubmitPortfolioItemOperation = makeApiMutation({
  name: "unsubmitPortfolioItem",
  path: "/api/dojoClass/{classId}/portfolio/{portfolioId}/unsubmit",
  method: "post",
  onMutate: (params) => {
    useAllClassroomFetcher.setQueriesData((draft) => {
      const classroomId = params.path?.classId;
      if (!classroomId) return;

      const classroomIndexToUpdate = draft.findIndex((classroom) => classroom._id === classroomId);
      if (classroomIndexToUpdate !== -1) {
        const currentCount = Number(draft[classroomIndexToUpdate]?.pendingPostCount);

        const pendingPostCount = Math.max(currentCount - 1, 0);
        draft[classroomIndexToUpdate] = { ...draft[classroomIndexToUpdate], pendingPostCount };
      }
    });
  },
  onSuccess: (_data, params) => {
    usePortfolioItemFetcher.setQueriesData(
      (draft) => {
        draft.state = "saved";
      },
      // TODO, are these the same ID??
      { postId: params.path.portfolioId },
    );

    usePortfolioStudentPostsFetcher.invalidateQueries();
    usePortfolioAssignmentPostsFetcher.invalidateQueries();
    usePortfolioAssignmentFetcher.invalidateQueries();
    usePortfolioAssignmentsFetcher.invalidateQueries();
    usePortfolioAllPostsFetcher.invalidateQueries();
    usePortfolioCountsSummaryFetcher.invalidateQueries();
  },
});

export const useCreatePortfolioAssignmentOperation = makeApiMutation({
  name: "createPortfolioAssignment",
  path: "/api/portfolioAssignment",
  method: "post",
  onSuccess: () => {
    usePortfolioAssignmentFetcher.invalidateQueries();
    usePortfolioAssignmentsFetcher.invalidateQueries();
  },
});

export const useEditPortfolioAssignmentOperation = makeApiMutation({
  name: "editPortfolioAssignment",
  path: "/api/portfolioAssignment/{assignmentId}",
  method: "put",
  onSuccess: () => {
    usePortfolioAssignmentFetcher.invalidateQueries();
    usePortfolioAssignmentsFetcher.invalidateQueries();
  },
});

type CreatePortfolioCommentOperationRequestBody = APIRequestBody<
  "/api/dojoClass/{classId}/portfolio/{postId}/comments",
  "post"
>;
// teacher is used in usePortfolioItemFetcher
type CreatePortfolioCommentOperationParams = APIRequestParameters<
  "/api/dojoClass/{classId}/portfolio/{postId}/comments",
  "post"
>["path"] &
  CreatePortfolioCommentOperationRequestBody & { teacher: TeacherSession };
type CreatePortfolioCommentOperationResponse = APIResponse<
  "/api/dojoClass/{classId}/portfolio/{postId}/comments",
  "post"
>;

export const useCreatePortfolioCommentOperation = makeMutation<
  CreatePortfolioCommentOperationParams,
  CreatePortfolioCommentOperationResponse
>({
  name: "createPortfolioComment",
  fn: async ({ classId, postId, body }): Promise<CreatePortfolioCommentOperationResponse | Error> => {
    return (
      await callApi({
        method: "POST",
        path: `/api/dojoClass/${classId}/portfolio/${postId}/comments`,
        body: {
          body,
        },
      })
    ).body;
  },
  onSuccess: (_data, params) => {
    usePortfolioItemFetcher.invalidateQueries({ postId: params.postId });
    usePortfolioItemFetcher.setQueriesData(
      (draft) => {
        const teacher = params.teacher;

        if (!draft.comments) draft.comments = [];

        const commentedAt = new Date().toISOString();

        draft.comments.push({
          entityType: "teacher",
          entityId: teacher._id,
          commentedAt,
          firstName: teacher.firstName,
          lastName: teacher.lastName,
          title: teacher.title,
          _id: uniqueId(`comment_${params.postId}`),
          avatarURL: teacher?.avatarURL,
          body: params.body,
          contents: {
            body: params.body,
            language: teacher.locale,
          },
          translatedContents: null,
          attachments: [],
        });
      },
      { postId: params.postId },
    );
  },
});

type DeletePortfolioCommentOperationParams = APIRequestParameters<
  "/api/dojoClass/{classId}/portfolio/{postId}/comments/{commentId}",
  "delete"
>["path"];
type DeletePortfolioCommentOperationResponse = { status: number };

export const useDeletePortfolioCommentOperation = makeMutation<
  DeletePortfolioCommentOperationParams,
  DeletePortfolioCommentOperationResponse | void
>({
  name: "deletePortfolioComment",
  fn: async ({ classId, postId, commentId }): Promise<void | Error> => {
    // API will 404 if the post no longer exists
    try {
      await callApi({
        method: "DELETE",
        path: `/api/dojoClass/${classId}/portfolio/${postId}/comments/${commentId}`,
      });
    } catch (ex: any) {
      if (ex?.response?.status === 404) {
        return;
      }
      throw ex;
    }
  },
  onSuccess: () => {
    usePortfolioItemFetcher.invalidateQueries();
  },
});

export const usePortfolioItemFetcher = makeMemberQuery({
  path: `/api/dojoClass/{classId}/portfolio/{postId}`,
  fetcherName: "portfolioItem",
  dontThrowOnStatusCodes: [404],
});
export type PortfolioItem = MemberFetcherReturnType<typeof usePortfolioItemFetcher>;

type UnlikePortfolioItemOperationParams = { classId: string; postId: string };

export const useUnlikePortfolioItemOperation = makeMutation<UnlikePortfolioItemOperationParams, { status: number }>({
  name: "unlikePortfolioItem",
  async fn({ classId, postId }) {
    return await callApi({
      method: "POST",
      path: "/api/storyBatchAction",
      body: {
        actions: [{ action: "unlike", targetType: "class", targetId: classId, postId }],
      },
    });
  },
  onMutate: (params) => {
    const sessionData = useSessionFetcher.getQueryData();
    const teacherId = sessionData?.teacher?._id;

    usePortfolioItemLikesFetcher.setQueriesData(
      (draft) => {
        return draft.filter((item) => item._id !== teacherId);
      },
      { postId: params.postId },
    );
  },
  onSuccess: () => {
    usePortfolioItemLikesFetcher.invalidateQueries();
  },
});

export const useLikePortfolioItemOperation = makeMutation<UnlikePortfolioItemOperationParams, { status: number }>({
  name: "likePortfolioItem",
  async fn({ classId, postId }) {
    return await callApi({
      method: "POST",
      path: "/api/storyBatchAction",
      body: {
        actions: [{ action: "like", targetType: "class", targetId: classId, postId }],
      },
    });
  },
  onMutate: (params) => {
    const sessionData = useSessionFetcher.getQueryData();
    const teacherId = sessionData?.teacher?._id;
    if (teacherId) {
      usePortfolioItemLikesFetcher.setQueriesData(
        (draft) => {
          draft.push({ type: "teacher", _id: teacherId, likedAt: new Date().toISOString() });
        },
        { postId: params.postId },
      );
    }
  },
  onSuccess: () => {
    usePortfolioItemLikesFetcher.invalidateQueries();
  },
});

type PortfolioItemLikesFetcherParams = APIRequestParameters<
  "/api/dojoClass/{classId}/storyPost/{postId}/likes",
  "get"
>["path"] & { teacherId: string };

type PortfolioItemLikesFetcherResponse = APIResponse<
  "/api/dojoClass/{classId}/storyPost/{postId}/likes",
  "get"
>["_items"][number];

export const usePortfolioItemLikesFetcher = makeCollectionQuery<
  "/api/dojoClass/{classId}/storyPost/{postId}/likes",
  never,
  PortfolioItemLikesFetcherResponse,
  PortfolioItemLikesFetcherParams
>({
  fetcherName: "portfolioItemLikes",
  dontThrowOnStatusCodes: [404],
  // needed to add the ?withStudentCommentsAndLikes=true for this to bring those likes also
  path: "/api/dojoClass/{classId}/storyPost/{postId}/likes",
  query: { withStudentCommentsAndLikes: "true" },
});

export const usePortfolioItemViewsFetcher = makeCollectionQuery({
  fetcherName: "portfolioItemViews",
  dontThrowOnStatusCodes: [404],
  path: "/api/dojoClass/{targetId}/storyPost/{postId}/views",
});

export const usePortfolioAssignmentsFetcher = makeCollectionQuery({
  path: "/api/portfolioAssignment",
  query: { includeMultipageWorksheets: "true" },
  queryParams: ["classId", "completed", "limit"],
  fetcherName: "portfolioAssignments",
});

export const usePortfolioAllPostsFetcher = makeCollectionQuery({
  fetcherName: "portfolioAllPosts",
  path: "/api/dojoClass/{classId}/portfolio",
  query: { state: "all", includeMultipageWorksheets: "true" },
});

export const usePortfolioCountsSummaryFetcher = makeMemberQuery({
  path: `/api/dojoClass/{classId}/portfolioSummary`,
  fetcherName: "usePortfolioCountsSummaryFetcher",
});

// helper types to make it more readable in clients
export type Post = CollectionFetcherReturnType<typeof usePortfolioAssignmentPostsFetcher>;
export type Assignment = MemberFetcherReturnType<typeof usePortfolioAssignmentFetcher>;
type AssignmentStudents = Assignment["students"];
export type AssignmentStudent = AssignmentStudents[number];
export type AssignmentActivity = Assignment["activity"];
export type AssignmentActivityAttachment = NonNullable<AssignmentActivity["attachments"]>[number];
export type WorkFormat = AssignmentActivity["workFormat"];
export type PortfolioItemLike = CollectionFetcherReturnType<typeof usePortfolioItemLikesFetcher>;
export type PortfolioItemView = CollectionFetcherReturnType<typeof usePortfolioItemViewsFetcher>;
export type PortfolioPost = CollectionFetcherReturnType<typeof usePortfolioAllPostsFetcher>;
export type PortfolioStudentPosts = CollectionFetcherReturnType<typeof usePortfolioStudentPostsFetcher>;

export type UploadedVideoInfo = Omit<UploadedFileInfo, "type" | "metadata"> & {
  type: "video";
  metadata: Omit<UploadedFileInfo["metadata"], "contentType"> & {
    contentType: AssignmentActivityAttachment["metadata"]["contentType"];
  };
};

export type ActivityStateAttachment = Omit<AssignmentActivityAttachment, "_id"> & { _id?: string; fullPath?: string };
export type ActivityState = {
  selectedOption: WorkFormat;
  activityName: string;
  activityInstructions: string;
  worksheetUrl: string;
  worksheetFileName: string;
  studentIds: string[];
  attachments: ActivityStateAttachment[];
};
