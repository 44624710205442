import * as logClient from "@classdojo/log-client";
import alarmMp3 from "app/assets/mp3/alarm.mp3";
import clickMp3 from "app/assets/mp3/click.mp3";
import nMp3 from "app/assets/mp3/n.mp3";
import neutralNMp3 from "app/assets/mp3/neutral-n.mp3";
import neutralPMp3 from "app/assets/mp3/neutral-p.mp3";
import pMp3 from "app/assets/mp3/p.mp3";
import redeemPointsMp3 from "app/assets/mp3/redeem-points.mp3";
import { soundManager, type soundmanager } from "app/lib/soundmanager2-fork/soundmanager2";
import env from "app/utils/env";

const SOUND_CONFIG: Record<string, string> = {
  alarm: alarmMp3,
  click: clickMp3,
  n: nMp3,
  "neutral-n": neutralNMp3,
  p: pMp3,
  "neutral-p": neutralPMp3,
  "redeem-points": redeemPointsMp3,
};

const sounds: Record<string, soundmanager.SMSound> = {};

// Make sure the fallback swf files are loaded in the bundle as static assets
// in a specific folder! (no caching for them).
if (!env.isTesting) {
  soundManager.setup({
    url: "/soundmanager_swf/",
    preferFlash: false,
    flashVersion: 9,
    debugMode: false,
    onready: () => {
      for (const soundName in SOUND_CONFIG) {
        try {
          const url = SOUND_CONFIG[soundName];
          const sound = soundManager.createSound({
            id: soundName,
            url,
            volume: 80,
          });
          sound.load({});
          sounds[soundName] = sound;
          // eslint-disable-next-line no-catch-all/no-catch-all
        } catch {
          // ignore error on sound initialization
          // "arg.trim is not a function"
        }
      }
    },
  });

  soundManager.beginDelayedInit();
}

const soundsUtil = {
  play(soundName: string) {
    if (env.isTesting) return;
    const sound = sounds[soundName];
    if (sound) {
      try {
        sound.play().catch(() => {
          logClient.sendMetrics([{ type: "increment", value: 1, metricName: "teach.media.sound.play.error" }]);
        });
        // eslint-disable-next-line no-catch-all/no-catch-all
      } catch {
        logClient.sendMetrics([{ type: "increment", value: 1, metricName: "teach.media.sound.play.error" }]);
      }
      return sound;
    }
    logClient.logException(new Error(`Tried to play unknown sound: ${soundName}`), []);
    // eslint-disable-next-line no-console
    console.error("Unknown sound:", soundName);
  },
};

export default soundsUtil;
