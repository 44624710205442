import { format, isSame, subDays } from "@web-monorepo/dates";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { TranslatedString } from "@web-monorepo/i18next";

export function makeTimestampString(time: Date, locale?: string): TranslatedString {
  if (!time) throw new Error("Must pass in a time!");

  const now = new Date();

  const sameYear = isSame(now, time, { mode: "year" });
  const today = isSame(now, time, { mode: "day" });
  if (today) {
    return format(time, { hour: "numeric", minute: "2-digit", locale });
  }

  const yesterday = isSame(subDays(now, 1), time, { mode: "day" });
  if (yesterday) {
    return autoTranslate("Yesterday");
  }

  if (sameYear) {
    return format(time, {
      month: "short",
      day: "numeric",
      year: "numeric",
      locale,
    });
  }

  return format(time, {
    month: "numeric",
    day: "2-digit",
    year: "numeric",
    locale,
  });
}
