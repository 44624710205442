import { APIRequestParameters, CollectionFetcherReturnType } from "@web-monorepo/shared/api/apiTypesHelper";
import { NOOP } from "@web-monorepo/shared/reactQuery";
import { useCallback } from "react";
import { useInterval } from "@web-monorepo/hooks";
import {
  useParentConnectionRequestsFetcher,
  useParentSchoolConnectionRequestsFetcher,
} from "app/pods/parentConnectionRequests";
import { useFetchedTeacher, useIsSchoolLeaderInUnownedClass } from "app/pods/teacher";
import { callOnceActive } from "app/views/utils/userActivity";
import { useClassroomFetcher } from "app/pods/classroom";
import { useNotificationsFetcher } from "@web-monorepo/notifications";

type UseNotificationsQuery = APIRequestParameters<"/api/notification", "get">["query"];

export type Target = { type: UseNotificationsQuery["targetType"]; id: UseNotificationsQuery["targetId"] };

export function refetchNotifications(targetType: Target["type"], targetId: Target["id"]) {
  useNotificationsFetcher.invalidateQueries({ targetId, targetType });
}

export type UseNotificationsResponse = CollectionFetcherReturnType<typeof useNotificationsFetcher>;

const NOTIFICATION_FETCH_INTERVAL = 1000 * 60 * 60; // one hour

/**
 * Refetches the notifications fetcher cached data once every hour.
 */
export function useNotificationsPoller({
  targetType,
  targetId,
}: {
  targetType: NonNullable<Target["type"]>;
  targetId: NonNullable<Target["id"]>;
}) {
  const teacher = useFetchedTeacher();
  const classroom = useClassroomFetcher(targetType === "class" ? { id: targetId } : NOOP).data;
  const isSchoolLeaderInUnownedClass = useIsSchoolLeaderInUnownedClass({ classroom });
  const shouldFetchNotifications =
    targetType !== "class" || (classroom && !classroom?.archived && !isSchoolLeaderInUnownedClass);

  const { refetch: refetchNotifications } = useNotificationsFetcher(
    shouldFetchNotifications ? { targetType, targetId } : NOOP,
    { staleTime: 0 },
  );
  const { refetch: refetchParentConnectionRequests } = useParentConnectionRequestsFetcher(
    {},
    {
      staleTime: 0,
    },
  );

  const { refetch: refetchParentSchoolConnectionRequests } = useParentSchoolConnectionRequestsFetcher(
    teacher.isSchoolAdmin ? {} : NOOP,
    {
      staleTime: 0,
    },
  );

  const refetchNotificationsCb = useCallback(() => {
    refetchNotifications({ cancelRefetch: false });
    refetchParentConnectionRequests({ cancelRefetch: false });
    refetchParentSchoolConnectionRequests({ cancelRefetch: false });
  }, [refetchNotifications, refetchParentConnectionRequests, refetchParentSchoolConnectionRequests]);

  useInterval(() => {
    callOnceActive(refetchNotificationsCb);
  }, NOTIFICATION_FETCH_INTERVAL);
}
