import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { components } from "@classdojo/ts-api-types/api";
import callApi, { CallApiDefaultResponse } from "@web-monorepo/infra/callApi";
import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import { APIRequestBody, APIRequestParameters, APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";
import { shouldEnforceLastNameForTeacher } from "@web-monorepo/shared/localization";
import { useClassMessageThreadsFetcher } from "@web-monorepo/shared/messaging/hooks";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import { makeMutation, NOOP } from "@web-monorepo/shared/reactQuery";
import { produce } from "immer";
import sortBy from "lodash/sortBy";
import partition from "lodash/partition";
import map from "lodash/map";
import { useMemo } from "react";
import type { AnyAction } from "redux";
import * as Classroom from "app/pods/classroom";
import { ClassroomPreferences, useClassroomFetcher } from "app/pods/classroom";
import { useHomeConnectionSummaryFetcher } from "app/pods/homeConnections/fetchers";
import * as Invitation from "app/pods/invitation";
import { useSchoolMinimalStudentsFetcher, useSchoolStudentsFetcher } from "app/pods/schoolStudents";
import { useStudentsFetcher } from "app/pods/student/fetchers";
import { useStudentsForTeacherFetcher } from "app/pods/teacher";
import { splitFirstAndLast, capitalize } from "app/utils/name";
import { firstName, lastName, addIdentifiableLastNames } from "app/utils/name";
import { useSelector } from "app/utils/reduxHooks";
import { invalidateClassQueries, useSchoolAllClassesFetcher } from "@web-monorepo/shared/classroom";
import { useSchoolwidePointsDashboardFetcher } from "#/src/pages/(sidebar)/schools/[schoolId]/points/_api/fetchers";

export type StudentsWithClassPreferences = NonNullable<
  ReturnType<typeof useStudentsWithClassPreferencesFetcher>["data"]
>;

// TODO: TSM: Some components receive a student that could either be from a school or class response.
// we need to check if we don't want to just use this generic school or class student everywhere instead of one or the other.
export type SchoolStudent = APIResponse<"/api/dojoSchool/{schoolId}/student", "get">["_items"][number];
export type SchoolMinimalStudent = APIResponse<"/api/dojoSchool/{schoolId}/studentList", "get">["_items"][number];
export type ClassStudent = APIResponse<"/api/dojoClass/{classId}/student", "get">["_items"][number];
export type Student = SchoolStudent | ClassStudent;

export type StudentBlockedParentConnection = Student["blockedParentConnections"][number];
export type StudentParentConnection = ClassStudent["parentConnections"][number];
export type StudentAllParentConnection = StudentParentConnection | StudentBlockedParentConnection;
export type StudentPendingParentConnection = StudentParentConnection & { status: "pending" };
export type StudentConnectedParentConnection = StudentParentConnection & { status: "connected" };

export type UnsavedStudent = {
  firstName: Student["firstName"];
  lastName: Student["lastName"];
  avatarNumber?: number;
  tempId?: string;
  _id?: string;
};

export type ExistingOrUnsavedStudent = Student | UnsavedStudent;

export type StudentClassroom = Student["classes"][number];

const STATE_KEY = "students";

type StudentState = {
  inviteErrors: Record<string, string[]>;
  created: Record<string, CreatedStudent>;
};

type StudentsSlice = {
  [STATE_KEY]: StudentState;
};

const initialState: StudentState = {
  inviteErrors: {}, // by student user id
  created: {}, // by clientId
};

// Selectors

const normalizeStudents = (students: ClassStudent[], inviteErrors: Record<string, string[]>) => {
  const sortedStudents = sortBy(students, "_id");
  return sortedStudents.map((s) => filterFailedParentConnections(inviteErrors, s));
};

const sortWithClassPreferences = (students?: ClassStudent[], classPreferences?: ClassroomPreferences) => {
  if (classPreferences?.sort === "lastName") {
    return sortBy<ClassStudent>(students ?? [], [lastName, firstName]);
  }
  return sortBy<ClassStudent>(students ?? [], [firstName, lastName]);
};

const adjustLastNames = (students: ClassStudent[], classPreferences: ClassroomPreferences) => {
  if (classPreferences.hideLastNames) {
    return addIdentifiableLastNames(students);
  }
  return students;
};

export const selectAllCreated = (state: StudentsSlice) => {
  return state[STATE_KEY].created;
};

const selectAllInviteErrors = (state: StudentsSlice) => state?.[STATE_KEY]?.inviteErrors;

// now that we keep track of the failed invitation, we will use that as auxilary information to
// filter possibly incorrect information inside student.parentConnections.
// make sure to always use this guy when getting a student
function filterFailedParentConnections(inviteErrors: Record<string, string[]>, student: ClassStudent) {
  return {
    ...student,
    parentConnections: student.parentConnections.filter((connection) => {
      const parentContact = connection.emailAddress || ("phoneNumber" in connection && connection.phoneNumber);
      return !inviteErrors[student._id]?.find((val) => val === parentContact);
    }),
  };
}

// Reducers

const createDoneHandler = (state: StudentState, action: AnyAction) => {
  if (!useCreateOperation.isDoneAction(action)) return state;

  return produce(state, (draft: StudentState) => {
    const student = action.payload.data.body;
    const clientId = action.payload.params.clientId;
    draft.created[clientId] = student;
  });
};

const invitationErrorsHandler = produce((draft: StudentState, action: AnyAction) => {
  if (Invitation.useInviteParentOperation.isErrorAction(action)) {
    // we keep track of the results of invitation batch here because API is actually untrustworthy
    // in this case. The parentConnection field in student loads connections that may be later
    // deleted. The failed responses coming from the batch will help us display the right
    // information on the frontend.
    const { studentId, emailOrPhone } = action.payload.params;
    const inviteErrors = draft.inviteErrors;

    if (emailOrPhone) {
      inviteErrors[studentId] = (inviteErrors[studentId] || []).concat(emailOrPhone);
    }
  }

  if (Invitation.useInviteParentOperation.isDoneAction(action)) {
    const { studentId, emailOrPhone } = action.payload.params;
    draft.inviteErrors[studentId] = draft.inviteErrors[studentId]?.filter((x) => x !== emailOrPhone!) ?? [];
  }
});

// Sagas

function makeBodyFromStudents(students: ListStudent[]) {
  return students.map(({ firstName, lastName, avatar, avatarNumber }) => {
    return {
      firstName,
      lastName,
      ...((avatar && { avatar }) || (avatarNumber && { avatarNumber }) || { avatarNumber: 1 }),
    };
  });
}

const finalStudentReducer = combineActionHandlers(initialState, [createDoneHandler, invitationErrorsHandler]);

const install: PodInstallFunction = (installReducer) => {
  installReducer(STATE_KEY, finalStudentReducer);
};

export default install;

// ---
// Fetcher

type ClassStudentsFetcherProps = {
  classId: string;
};

export const useNormalizedStudentsFetcher = (props: ClassStudentsFetcherProps | typeof NOOP) => {
  const result = useStudentsFetcher(props);
  const inviteErrors = useSelector(selectAllInviteErrors);
  const normalizedData = useMemo(() => normalizeStudents(result.data || [], inviteErrors), [inviteErrors, result.data]);
  return { ...result, data: normalizedData };
};

export const useStudentsWithClassPreferencesFetcher = (props: ClassStudentsFetcherProps) => {
  const result = useNormalizedStudentsFetcher(props);

  const classPreferences = Classroom.useClassroomPreferences(props);

  result.data = useMemo(() => {
    const data = sortWithClassPreferences(result.data, classPreferences);
    return adjustLastNames(data, classPreferences);
  }, [classPreferences, result.data]);

  return result;
};

type CreateFromListParams = APIRequestParameters<"/api/dojoClass/{classId}/student", "post">["path"] & {
  classroomId: string;
  students: APIRequestBody<"/api/dojoClass/{classId}/student", "post">;
};

type CreateResponse = APIResponse<"/api/dojoClass/{classId}/student", "post">;
export type CreatedStudent = Extract<CreateResponse, components["schemas"]["StudentResponse"]>;

type ListStudent = Extract<CreateFromListParams["students"], components["schemas"]["ClassStudentPOSTPayload"]>;

// Operations
export const useCreateFromListOperation = makeMutation<CreateFromListParams, { body: CreatedStudent[] }>({
  name: "createFromList",
  async fn({ classroomId, students }) {
    try {
      const studentArray = Array.isArray(students) ? students : [students];

      return await callApi({
        method: "POST",
        path: `/api/dojoClass/${classroomId}/student`,
        body: makeBodyFromStudents(studentArray),
      });
    } catch (err: any) {
      // return 400 error so caller can handle error display
      if (err.response && err.response.status === 400) return err;
      throw err;
    }
  },
  onSuccess: (_data, params) => {
    useHomeConnectionSummaryFetcher.invalidateQueries();
    useClassroomFetcher.invalidateQueries();
    useSchoolStudentsFetcher.invalidateQueries();
    useSchoolMinimalStudentsFetcher.invalidateQueries();
    useClassMessageThreadsFetcher.invalidateQueries({ classId: params.classroomId });
    useStudentsFetcher.invalidateQueries();
  },
  onMutate: (params) => {
    useClassroomFetcher.setQueriesData((draft) => {
      if (draft._id === params.classroomId) {
        const students = Array.isArray(params.students) ? params.students : [params.students];
        const studentCount = draft.studentCount ?? 0;

        draft.studentCount = studentCount + students.length;
      }
    });
  },
});

type CreateParams = { classroomId: string; name: string; clientId: string };

// clientId is passed through to the reducers
export const useCreateOperation = makeMutation<CreateParams, { body: CreatedStudent }>({
  name: "createStudent",
  fn: async ({ classroomId, name }) => {
    const [firstName, lastName] = splitFirstAndLast(capitalize(name));
    return await callApi({
      method: "POST",
      path: `/api/dojoClass/${classroomId}/student`,
      body: { firstName, lastName, avatarNumber: 1 },
    });
  },
  onSuccess: (data, params) => {
    useHomeConnectionSummaryFetcher.invalidateQueries();
    useStudentsFetcher.setQueriesData(
      (draft) => {
        const student = data.body;

        draft.push(student);

        // TODO: handle pendingStudents - We might want to invalidate here instead
      },
      { classId: params.classroomId },
    );
    useStudentsForTeacherFetcher.invalidateQueries();
    useSchoolStudentsFetcher.invalidateQueries();
    useSchoolMinimalStudentsFetcher.invalidateQueries();
    useClassroomFetcher.invalidateQueries({ id: params.classroomId });
    invalidateClassQueries();
    useSchoolAllClassesFetcher.invalidateQueries();
  },
  onMutate: (params) => {
    useClassroomFetcher.setQueriesData((draft) => {
      if (draft._id === params.classroomId) {
        draft.studentCount = (draft.studentCount ?? 0) + 1;
      }
    });
  },
});

// TODO: TSM we 'rename' to classroomId to avoid modifying the runtime. Once all is typed we can rename and use the same name as in API
export type CreateStudentsAndAddToClassroomParams = Omit<
  APIRequestParameters<"/api/dojoClass/{classId}/student", "post">["path"],
  "classId"
> & {
  classroomId: string;
  students: ListStudent[];
};

export const useCreateStudentsAndAddToClassroomOperation = makeMutation<
  CreateStudentsAndAddToClassroomParams,
  CallApiDefaultResponse | void
>({
  name: "createStudentsAndAddToClassroom",
  fn: async ({ classroomId, students }) => {
    // TODO: `students` does not have `_id` on it. I assume this code does not work.
    // ClassStudents do not have a parent connection, SchoolStudents do
    const [schoolStudents, classStudents] = partition(students, "_id");

    // Add class students to class
    if (classStudents.length) {
      await callApi({
        method: "POST",
        path: `/api/dojoClass/${classroomId}/student`,
        body: makeBodyFromStudents(classStudents),
      });
    }

    if (schoolStudents.length) {
      const studentIds = map(schoolStudents, "_id");
      await callApi({
        method: "POST",
        path: `/api/dojoClass/${classroomId}/addExistingStudents`,
        body: { studentIds },
      });
    }
  },
  onSuccess: (_data, params) => {
    useHomeConnectionSummaryFetcher.invalidateQueries();
    useStudentsFetcher.invalidateQueries();
    useStudentsForTeacherFetcher.invalidateQueries();
    useSchoolStudentsFetcher.invalidateQueries();
    useSchoolMinimalStudentsFetcher.invalidateQueries();
    useClassMessageThreadsFetcher.invalidateQueries({ classId: params.classroomId });
    useClassroomFetcher.invalidateQueries({ id: params.classroomId });
    invalidateClassQueries();
    useSchoolAllClassesFetcher.invalidateQueries();
    useSchoolwidePointsDashboardFetcher.invalidateQueries();
  },
});

type StudentPutBody = APIRequestBody<"/api/dojoClass/{classId}/student/{id}", "put">;
type UpdateStudentForClassroomParams = {
  classroomId: string;
  student: StudentPutBody;
};
type UpdateStudentForClassroomResult = APIResponse<"/api/dojoClass/{classId}/student/{id}", "put">;

export const useUpdateStudentForClassroomOperation = makeMutation<
  UpdateStudentForClassroomParams,
  { body: UpdateStudentForClassroomResult }
>({
  name: "updateStudentForClassroom",
  fn: async ({ classroomId, student }) => {
    const body: StudentPutBody = { _id: student._id, firstName: student.firstName, lastName: student.lastName };
    if (student.avatar) body.avatarUrl = student.avatar;
    return await callApi({
      method: "PUT",
      path: `/api/dojoClass/${classroomId}/student/${student._id}`,
      body,
    });
  },
  onMutate: (params) => {
    useStudentsFetcher.setQueriesData(
      (draft) => {
        const studentParam = params.student;
        const draftStudent = draft.find(({ _id }) => _id === studentParam._id);
        if (!draftStudent) return;
        draftStudent.firstName = studentParam.firstName;
        draftStudent.lastName = studentParam.lastName;
        if (studentParam.avatar && !draftStudent.shouldHatchEgg) {
          draftStudent.avatar = studentParam.avatar;
        }
      },
      { classId: params.classroomId },
    );
  },
  onSuccess: (data, params) => {
    useStudentsFetcher.setQueriesData(
      (draft) => {
        const draftStudentIndex = draft.findIndex(({ _id }) => _id === params.student._id);
        const draftStudent = draft[draftStudentIndex];
        if (draftStudentIndex !== -1 && !draftStudent.shouldHatchEgg) {
          draft[draftStudentIndex] = data.body;
        }
      },
      { classId: params.classroomId },
    );
  },
});

type DisconnectStudentParams = APIRequestParameters<
  "/api/studentUser/{studentUserId}/student/{studentId}",
  "delete"
>["path"] & {
  classroomId: string;
};

export const useDisconnectStudentOperation = makeMutation<DisconnectStudentParams, CallApiDefaultResponse>({
  name: "disconnectStudent",
  fn: async ({ studentId, studentUserId }) => {
    return await callApi({
      method: "DELETE",
      path: `/api/studentUser/${studentUserId}/student/${studentId}`,
      body: {}, // this API call errors without empty body
    });
  },
  onMutate: (params) => {
    useStudentsFetcher.setQueriesData(
      (draft) => {
        const draftStudent = draft.find(({ _id }) => _id === params.studentId);
        delete draftStudent?.studentUser;
      },
      { classId: params.classroomId },
    );
  },
});

export type RestoreStudentAvatarParams = Omit<
  APIRequestParameters<"/api/dojoClass/{classId}/student/{studentId}/unsetAvatar", "post">["path"],
  "classId"
> & {
  classroomId: string;
};

export const useRestoreStudentAvatarOperation = makeMutation<RestoreStudentAvatarParams, CallApiDefaultResponse>({
  name: "restoreStudentAvatar",
  fn: async ({ classroomId, studentId }) => {
    return await callApi({
      method: "POST",
      path: `/api/dojoClass/${classroomId}/student/${studentId}/unsetAvatar`,
    });
  },
  onSuccess: (_data, params) => {
    useStudentsFetcher.invalidateQueries({ classId: params.classroomId });
  },
});

type RemoveStudentFromClassroomParams = Partial<
  APIRequestParameters<"/api/dojoClass/{classId}/student/{id}", "delete">["path"]
> & {
  classroomId: string;
  studentId: string;
};

export const useRemoveStudentFromClassroomOperation = makeMutation<
  RemoveStudentFromClassroomParams,
  CallApiDefaultResponse | undefined
>({
  name: "removeStudentFromClassroom",
  fn: async ({ classroomId, studentId }) => {
    // Previous code has 2 modes, one with a real id, one with a client generated id
    // Only make the API call with the real id, but handler handles both
    if (studentId.length === 24) {
      return await callApi({
        method: "DELETE",
        path: `/api/dojoClass/${classroomId}/student/${studentId}`,
      });
    }
  },
  onMutate: (params) => {
    useClassroomFetcher.setQueriesData(
      (draft) => {
        draft.studentCount = (draft.studentCount ?? 0) - 1;
      },
      { id: params.classroomId },
    );
    useStudentsFetcher.setQueriesData(
      (draft) => {
        const draftStudentIndex = draft.findIndex(({ _id }) => _id === params.studentId);
        // @fetcher-revisit could not delete data[draftStudentIndex]
        if (draftStudentIndex !== -1) {
          draft.splice(draftStudentIndex, 1);
        }
      },
      { classId: params.classroomId },
    );
  },
  onSuccess: (_data, params) => {
    useClassMessageThreadsFetcher.invalidateQueries({ classId: params.classroomId });
  },
});

export const getStudentNameValidationErrorString = (
  teacher: { locale?: string; countryCode?: string },
  studentFirstName: string,
  studentLastName?: string,
) => {
  const lacksLastName = !studentLastName && shouldEnforceLastNameForTeacher(teacher);
  if (lacksLastName) {
    return autoTranslate(
      "Last name is required. Don't worry, student last names are not shown in class unless you enable this in display settings",
    );
  }

  const firstNameTooLong = studentFirstName && studentFirstName.length > 50;
  if (firstNameTooLong) {
    return autoTranslate("Please enter a first name with 50 letters or fewer");
  }

  const lastNameTooLong = studentLastName && studentLastName.length > 50;
  if (lastNameTooLong) {
    return autoTranslate("Please enter a last name with 50 letters or fewer");
  }
};
