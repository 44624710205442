import { createContext, useContext } from "react";
import type { Simplify } from "type-fest";
import { type Props as ViewProps } from "../VerificationPage";

type ArgsSuppliedByConsumer = "error";
export type GetCodeParams = Simplify<Pick<Omit<ViewProps, "fullscreen">, ArgsSuppliedByConsumer>>;
export type GetCode = (args: GetCodeParams) => Promise<string>;
export type SetFullscreen = (fullscreen: boolean) => void;

export type ContextType = {
  getCode: GetCode;
  close(): void;
  setFullscreen: SetFullscreen;
};

const noop = () => Promise.resolve("");
const CONTEXT = createContext<ContextType>({ getCode: noop, close: noop, setFullscreen: noop });

export const useOTCHandlers = () => useContext(CONTEXT);

export default CONTEXT;
