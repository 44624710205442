import { type FC, type PropsWithChildren, Component, useMemo, useState } from "react";
import { DDSDialog, DDSDialogContent, DDSDialogHeader, SemanticTypography } from "@web-monorepo/dds";
import { UserCancelledCodeEntryError } from "../errors";
import { TRANSLATOR_CONTEXT, type Translator } from "../i18n";
import { LOGGING_CONTEXT, useEventLogger } from "../logging";
import VerificationPage, { type Props as ViewProps } from "../VerificationPage";
import CONTEXT, { type GetCodeParams } from "./context";

let pageKey = 0;
type ProviderProps = PropsWithChildren<{
  entityType: "parent" | "teacher";
  translate: Translator;
}>;

class StifleRejectionErrors extends Component<PropsWithChildren> {
  static getDerivedStateFromError() {
    return null;
  }

  render() {
    return <>{this.props.children}</>;
  }

  componentDidCatch(error: Error): void {
    if (!(error instanceof UserCancelledCodeEntryError)) {
      throw error;
    }
  }
}

export const OTCPageProvider: FC<ProviderProps> = ({ children, entityType, translate }) => {
  const [otcPageProps, setOTCPageProps] = useState<ViewProps | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isFullscreen, setFullscreen] = useState(true);
  const logEvent = useEventLogger(entityType);

  const handlers = useMemo(() => {
    const close = () => {
      setOTCPageProps(null);
      setIsDialogOpen(false);
    };

    return {
      getCode: (params: GetCodeParams) =>
        new Promise<string>((onCode, onCancel) => {
          pageKey++;
          setOTCPageProps({
            ...params,
            entityType,
            onCode,
            onCancel: () => {
              onCancel(new UserCancelledCodeEntryError(params.error));
              close();
            },
          });
          if (!isDialogOpen) {
            setIsDialogOpen(true);
          }
        }),
      setFullscreen,
      close,
    };
  }, [entityType, isDialogOpen]);

  // Handle dialog close
  const handleDialogOpenChange = (open: boolean) => {
    if (!open && otcPageProps) {
      logEvent.cancelledOTPEntry({
        productEventNamespace: "one_time_codes",
      });
      otcPageProps.onCancel();
    }
  };

  return (
    <StifleRejectionErrors>
      <LOGGING_CONTEXT.Provider value={{ entityType }}>
        <CONTEXT.Provider value={handlers}>
          {children}
          <DDSDialog data-name="one_time_code" open={isDialogOpen} onOpenChange={handleDialogOpenChange}>
            <DDSDialogContent fullscreen={isFullscreen ? true : undefined}>
              <DDSDialogHeader title="One-Time Code" visuallyHideTitle />
              <TRANSLATOR_CONTEXT.Provider value={translate}>
                <SemanticTypography>
                  {otcPageProps && <VerificationPage key={pageKey} fullscreen={isFullscreen} {...otcPageProps} />}
                </SemanticTypography>
              </TRANSLATOR_CONTEXT.Provider>
            </DDSDialogContent>
          </DDSDialog>
        </CONTEXT.Provider>
      </LOGGING_CONTEXT.Provider>
    </StifleRejectionErrors>
  );
};
