import { makeCollectionQuery } from "@web-monorepo/shared/reactQuery";

export const usePendingTeachers = makeCollectionQuery({
  fetcherName: "pendingTeachers",
  path: "/api/school/{schoolId}/pending-teachers",
  dontThrowOnStatusCodes: [403],
});

export const useTeacherPendingClassesFetcher = makeCollectionQuery({
  fetcherName: "teacherPendingClasses",
  path: "/api/dojoSchool/{schoolId}/teacher/{teacherId}/pending-classes",
  dontThrowOnStatusCodes: [403],
});

export const useTeacherActiveClassesFetcher = makeCollectionQuery({
  fetcherName: "teacherActiveClasses",
  queryParams: ["includeWhereCollaborating"],
  path: "/api/dojoSchool/{schoolId}/teacher/{teacherId}/active-classes",
  dontThrowOnStatusCodes: [403],
});

export const useTeacherArchivedClassesFetcher = makeCollectionQuery({
  fetcherName: "teacherArchivedClasses",
  path: "/api/dojoSchool/{schoolId}/teacher/{teacherId}/archived-classes",
  dontThrowOnStatusCodes: [403],
});
