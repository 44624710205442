/*
  Metadata keys
*/
export const OPENED_HIGHLIGHTS = "hasSeenHighlights";
export const OPENED_SCHOOL_STORY = "openedSchoolStory";
export const DISMISSED_SCHOOL_STORY_WELCOME_CARD = "dismissedSchoolStoryWelcomeCard";
export const VERIFY_REMINDER_DISMISS_TIME = "verifyReminderDismissTime";
export const BIG_IDEAS_LAST_CLICK_TIME = "bigIdeasLastClickTime";
export const BIG_IDEAS_LAST_DISMISS_TIME = "bigIdeasLastDismissTime";
export const HAS_SEEN_SCHOOL = "hasSeenSchool";
export const APPLY_TO_BE_MENTOR_SCHOOL_ID = "applyToBeMentorSchoolId";
export const DISMISSED_SCHOOL_DIRECTORY_WELCOME_CARD = "dismissedSchoolDirectoryWelcomeCard";
export const SEEN_POTENTIAL_PARENTS = "seenPotentialParents";
export const HAS_SEEN_PARENT_REQUESTS = "hasSeenParentRequests";
export const HIDE_PORTFOLIO_NUX = "hidePortfolioNux";
export const HIDE_MESSAGE_ANNOUNCEMENT_HOLDOUTS = "hideMessageAnnouncementHoldouts";
export const HIDE_MESSAGE_UPSELL_MODAL = "hideMessageUpsellModal";
export const HIDE_URGENT_MESSAGE_NUX = "hideUrgentMessageNux";
export const PARENT_READ_RECEIPTS_DISABLED = "parentReadReceiptsDisabled";

export const DISMISSED_CONNECT_PARENTS_NUX_FOR_CLASS = "dismissedConenctParentNuxForClass";
export const CLASS_STARTER_CHAT_NUX_SEEN_TIMESTAMPS = "teacherClassStarterChatNuxSeenTimestamps";
export const CLASS_STARTER_CHAT_UPSELL_HEADER_DISMISSED_TIMESTAMPS =
  "teacherClassStarterChatUpsellHeaderDismissedTimestamps";

export const SEEN_ADD_TO_DIRECTORY_MODAL = "seenAddStudentModal";
export const CLASSES_HID_ADD_TO_DIRECTORY_MODAL = "classesHidAddStudentModal";
export const CLASSES_HID_ADD_TO_DIRECTORY_BANNER = "classesHidAddStudentBanner";

// Add students to directory V2 (some tests depend on fixture data that includes this):
export const CLASSES_HIDE_ADD_STUDENT_BANNER_V2 = "classesHideAddStudentBannerV2_3";
export const CLASSES_HIDE_ADD_STUDENT_MODAL_V2 = "classesHideAddStudentModalV2_3";

export const DISMISSED_MOJOTIP_INVITE_PARENTS = "dismissedMojoTipInviteParents";
export const HIDE_ALL_PARENTS_CONNECTED_FOR_CLASS_ID = "hideAllParentsConnectedForClassId";

export const DISMISSED_TOOLTIP_ADD_CLASS = "dismissedTooltipAddClass";

export const MINIMIZE_INSIGHTS = "minimizeInsights";

export const TEACHER_NOTIFICATION_MENTOR_DATE_KEY = "teacherNotifiedMentorDateKey";

export const MARKETING_EMAIL_OPT_OUT_SHOWN = "marketingEmailOptOutShown";
export const HAS_SEEN_EGG_HATCH_NUX = "hasSeenEggHatchNux";

export const HAS_SEEN_HOME_CONNECTIONS_NUX = "hasSeenHomeConnectionsNux";
export const HAS_DISMISSED_HOME_CONNECTIONS_TOOLTIP = "hasDismissedHomeConnectionsTooltip";
export const HAS_DISMISSED_PARENT_READ_RECEIPTS_NUX = "teacherHasSeenPremiumReadReceiptsInboxNUX";

export const HAS_ACCEPTED_PRIVACY_POLICY_2022 = "2022-01-28-PP_update_consent_approved";

export const HAS_DISMISSED_STAFF_MESSAGING_BETA_NUX = "hasDismissedStaffMessagingBetaNux";
export const HAS_DISMISSED_TEACHER_GET_STARTED_MODAL = "hasDismissedTeacherGetStartedModal";
export const IGNORED_SUGGGESTED_TEACHER_INVITES = "ignoredSuggestedTeacherInvites";

export const HAS_SEEN_SWAG_MODAL = "hasSeenSwagModal";
export const HAS_SEEN_CDS_STAFF_COMPLETED_MODAL = "hasSeenCdsStaffCompletedModal";

export const HAS_SEEN_SCHOOL_ADMIN_MODAL = "hasSeenSchoolAdminModal";
export const HAS_SEEN_INVITE_MENTOR_MODAL = "hasSeenInviteMentorModal";
export const HAS_SEEN_ADD_CLASS = "hasSeenAddClass";

export const HAS_DISMISSED_DOJO_ISLANDS_ENTRYPOINT = "hasDismissedMonsterIslandEntrypoint";
export const HAS_DISMISSED_DOJO_ISLANDS_TAB_TOOLTIP = "hasDismissedDojoIslandsTabTooltip";
export const HAS_DISMISSED_NO_CONNECTIONS_MODAL = "hasDismissedNoConnectionsModal";

export const HAS_DISMISSED_SCHOOL_LEVEL_INDICATOR = "hasDismissedSchoolLevelIndicator";

export const CDS_RETURNING_USER_EXPLANATION_DISMISS_DATE = "cdsReturningUserExplanationDismissDate";

export const DOJO_ISLANDS_MODAL_DISMISS_DATE = "dojoIslandsModalDismissDate";

export const ONCE_PER_DAY = "oncePerDay";
export const POST_FINISHING_CLASS_SHOW_MODAL = "postFinishingClassShowModal";
export const INVITE_SCHOOL_LEADER_MODAL_DISMISS_DATE = "inviteSchoolLeaderModalDismissDate";
export const SCHEDULE_STORY_POST_NUX = "teacherScheduleStoryPostNux";
export const SCHEDULE_STORY_POST_TOOLTIP = "teacherScheduleStoryButtonTooltip";
export const ACCEPTED_AI_TERMS = "acceptedAITerms";
export const HAS_CONFIRMED_EMAIL = "hasConfirmedEmail";
export const PLASTICINE_ICONS_MODALS_DISMISS_DATE = "plasticineIconsModalDismissDate";
export const SCHOOL_ANNOUNCEMENTS_NUX_DISMISSED = "schoolAnnouncementsNuxDismissed";
export const BECOME_MENTOR_BANNER_MAR2025_DISMISS_DATE = "becomeMentorBannerMar2025DismissDate";
export const BECOME_MENTOR_BANNER_MAR2025_SHOW_DATE = "becomeMentorBannerMar2025ShowDate";
export const BECOME_MENTOR_MODAL_MAR2025_DISMISS_DATE = "becomeMentorModalMar2025DismissDate";
export const ALREADY_SCHOOLWIDE_NEED_1ON1_MODAL_DISMISS_DATE = "alreadySchoolwideNeed1on1ModalDismissDateV2";
export const EVENT_REMINDER_GUIDE = "teacherEventReminderGuide";
export const SHOW_SCHOOL_ANNOUNCEMENTS_TOOLTIP_NUX = "showSchoolAnnouncementTooltipNux";
export const AUNZ_MENTORS_EOY_ACTIVATION_MODAL_DISMISS_DATE = "AUNZMentorsEOYActivationModalDismissDate";
export const DOJO_ISLANDS_FTUE_COMPLETED_CLASSES = "DojoIslandsFTUEModalSeenClasses";
export const DOJO_ISLANDS_FTUE_VISITS = "DojoIslandsFTUEModalVisits";
export const DOJO_ISLANDS_FTUE_CURRENT_STEP = "DojoIslandsFTUEModalCurrentStep";
export const HAS_SEEN_MENTOR_CELEBRATION_MODAL = "hasSeenMentorCelebration";
export const EVENT_SIGNUPS_NUX = "eventSignupsNux";
export const ONBOARDING_EMAIL_VERIFY_STATE = "onboardingEmailVerifyState";
export const SAY_HELLO_SL_BANNER_DISMISS_DATE = "sayHelloSLBannerDismissDate";
export const MENTOR_BTS_2024_MODAL_DISMISS_DATE = "mentorBTS2024ModalDismissDate";
export const UNIFIED_INBOX_NUX_DISMISSED = "unifiedInboxNuxDismissed";
export const SHOW_UNIFIED_INBOX_TOOLTIP_NUX = "showUnifiedInboxTooltipNux";
export const RE_OPTIN_TEACHERS_DISMISS_DATE = "reOptInTeachersDismissDate";
export const MENTOR_RESOURCE_KIT_PUSH_MODAL_DISMISS_DATE = "MentorResourceKitPushModalDismissDate";
export const OIDC_NUDGE_BANNER_DISMISS_DATE = "oidcNudgeBannerDismissDate";
export const ENCOURAGE_SCHOOL_INVITES_MODAL_DISMISS_DATE = "encourageSchoolInvitesModalDismissDate";
export const MV_POINTS_MODALS_DISMISS_DATE = "mvPointsModalsDismissDate";
export const MORE_FOR_MENTORS_2024Q4_FOLLOWUP_MODAL_DISMISS_DATE = "MoreForMentors2024q4FollowupModalDismissDate";
export const FILL_OUT_SURVEY_Q32024_MODAL_DISMISS_DATE = "fillOutSurveyQ32024ModalDismissDate";
export const MONSTER_OF_THE_YEAR_2025_MODAL = "monsterOfTheYear2025ModalDismissDate";
export const DISTRICTS_1PAGER_MODAL_DISMISS_DATE = "Districts1PagerModalDismissDate";
export const DISTRICTS_HUB_MODAL_DISMISS_DATE = "DistrictsHubModalDismissDate";
