import { ReactNode } from "react";
import { IconUser } from "@web-monorepo/dds-icons";
import { DDSIconButtonClasses } from "@web-monorepo/dds";
import { BaseNotificationItem } from "./NotificationItem";

type RequestCellProps = {
  children: ReactNode;
  accessory?: ReactNode;
} & Pick<Parameters<typeof BaseNotificationItem>[0], "onClick" | "date" | "badge">;

export const RequestCell = ({ date, badge, children, accessory, onClick }: RequestCellProps) => (
  <BaseNotificationItem
    onClick={onClick}
    accessory={
      accessory ?? (
        <div
          className={[
            DDSIconButtonClasses.iconButton,
            DDSIconButtonClasses.primarySubtle,
            DDSIconButtonClasses.medium,
          ].join(" ")}
        >
          <IconUser size="m" />
        </div>
      )
    }
    text={children}
    date={date}
    badge={badge}
  />
);
