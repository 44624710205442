import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { GrandstanderText, GrandstanderTextProps } from "./GrandstanderText";

export type AccentTextLevelsType = 1 | 2 | "m";

export type IslandsAccentTextProps = {
  level?: AccentTextLevelsType;
} & GrandstanderTextProps;

export function IslandsAccentText({ level = 1, ...props }: IslandsAccentTextProps) {
  return <GrandstanderText sx={accentTextStyles[level]} {...props} />;
}

const accentTextStyles: Record<AccentTextLevelsType, ThemeUIStyleObject> = {
  1: {
    fontSize: "18px",
    lineHeight: "23px",
    fontWeight: "dt_extraBold",
    letterSpacing: "-0.5px",
  },
  2: {
    fontSize: "15px",
    lineHeight: "18px",
    fontWeight: "dt_extraBold",
    letterSpacing: "-0.5px",
  },
  m: {
    fontSize: "12px",
    lineHeight: "13px",
    fontWeight: "dt_extraBold",
    letterSpacing: "-0.5px",
  },
};
