import maxBy from "lodash/maxBy";
import { useEffect, useRef, useMemo } from "react";
import * as logClient from "@classdojo/log-client";

import { useMarkNotificationsReadOperation, useNotificationsFetcher } from "./fetchers";
import { parseJSON } from "@web-monorepo/dates";
import type { Notification } from "./types";

export function getNotificationDateString(n: Notification): string | null {
  if ("createdAt" in n && n.createdAt) return n.createdAt;
  if ("published" in n && n.published) return n.published;
  return null;
}

export function getNotificationDate(n: Notification): Date {
  const str = getNotificationDateString(n);
  return str ? parseJSON(str) : new Date(NaN);
}

export function isClassDojoNotification(notification: Notification) {
  return notification && !!notification._id && notification.actor && notification.actor.type === "dojo";
}

function isNotificationRead(n: Notification): boolean {
  if ("read" in n && typeof n.read === "boolean") return n.read;
  return false;
}

export const isUnreadClassDojoNotification = (notification: Notification) =>
  isClassDojoNotification(notification) && "read" in notification && notification.read === false;

function normalizeNotification(n: Notification): Notification {
  const parsedSecondaryView = parseSecondaryView("secondaryView" in n && n.secondaryView);
  if (parsedSecondaryView) {
    return Object.assign({}, n, {
      object: Object.assign({}, "object" in n ? n.object : {}, {
        secondaryView: parsedSecondaryView,
      }),
    });
  }

  return n;
}

export type NotificationWithObject = Extract<Notification, { object: NonNullable<unknown> }>;
export type NotificationObject = NotificationWithObject["object"];

export type SecondaryView = NonNullable<NotificationObject["secondaryView"]>;

function parseString(o: Record<string, string>, k: string): string | undefined {
  if (k in o && typeof o[k] === "string") return o[k];
  return undefined;
}

function parseSecondaryView(o: unknown): SecondaryView | undefined {
  if (!o || typeof o !== "object") return undefined;
  const s = {} as SecondaryView;
  const r = o as Record<string, string>;

  s.cta = parseString(r, "cta");
  s.header = parseString(r, "header");
  s.image = parseString(r, "image");
  s.text = parseString(r, "text");

  return s;
}

function useSortedNormalizedNotifications(
  arg: Parameters<typeof useNotificationsFetcher>[0],
): ReturnType<typeof useNotificationsFetcher> {
  const { data, ...rest } = useNotificationsFetcher(arg);
  return {
    data: useMemo(
      () =>
        data
          ? data
              .map(normalizeNotification)
              .sort((a, b) => getNotificationDate(b).getTime() - getNotificationDate(a).getTime())
          : data,
      [data],
    ),
    ...rest,
  } as ReturnType<typeof useNotificationsFetcher>;
}

export function useNotificationCenterVM(arg: Parameters<typeof useNotificationsFetcher>[0]) {
  const unreadNotificationIds = useRef<string[]>([]);
  const { data: notifications } = useSortedNormalizedNotifications(arg);
  const { mutate: markNotificationRead } = useMarkNotificationsReadOperation();

  const logReceivedUnreadNotifications = (notificationIds: string[]) => {
    notificationIds.forEach((id) => {
      void logClient.logEvent({
        eventName: "web.in_app_notification.receive",
        eventValue: id,
      });
    });
  };

  const logViewUnreadNotificationsEvent = (notificationIds: string[]) => {
    notificationIds.forEach((id) => {
      void logClient.logEvent({
        eventName: "web.in_app_notification.viewInList",
        eventValue: id,
      });
    });
  };

  useEffect(() => {
    const receivedUnreadIds = notifications
      ?.filter(isUnreadClassDojoNotification)
      .map((notification) => notification._id);
    receivedUnreadIds?.sort();

    if (
      receivedUnreadIds &&
      (unreadNotificationIds.current.length !== receivedUnreadIds?.length ||
        unreadNotificationIds.current.some((x) => !receivedUnreadIds.includes(x)))
    ) {
      unreadNotificationIds.current = receivedUnreadIds;
      logReceivedUnreadNotifications(unreadNotificationIds.current);
    }
  }, [notifications]);

  const onOpen = () => {
    if (!notifications) return;
    logViewUnreadNotificationsEvent(unreadNotificationIds.current);

    const latest = maxBy(notifications, (n) => getNotificationDate(n) && getNotificationDate(n));
    if (!latest?._id || !("createdAt" in latest) || !latest.createdAt) return;

    console.log("Marking as read.");
    markNotificationRead({
      body: {
        ...(typeof arg === "object" && "targetId" in arg && arg.targetId ? { targetId: arg.targetId } : {}),
        lastReadId: latest._id,
        lastReadPublishAt: latest.createdAt,
      },
    });
  };

  return {
    notifications,
    unreadCount: notifications?.filter((n) => !isNotificationRead(n)).length ?? 0,
    onOpen,
  };
}
