import callApi, { CallApiDefaultResponse } from "@web-monorepo/infra/callApi";
import { APIResponseError, isApiResponseError } from "@web-monorepo/infra/responseHandlers";
import { APIRequestBody, APIRequestParameters, APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";
import { useAllClassroomFetcher } from "@web-monorepo/shared/classroom";
import { DojoError } from "@web-monorepo/shared/errors/errorTypeMaker";
import {
  makeApiMutation,
  makeCollectionQuery,
  makeMemberQuery,
  makeMutation,
  NOOP,
} from "@web-monorepo/shared/reactQuery";
import env from "@web-monorepo/shared/utils/env";
import errors from "app/errorTypes";
import { SessionResponse, useSessionFetcher } from "app/pods/auth";
import { type Classroom, userIsClassTeacher } from "app/pods/classroom";
import { getSchoolTeachers, useSchoolFetcher } from "app/pods/school";
import getApiError from "app/pods/shared/util/getApiError";
import matchesError from "app/pods/shared/util/matchesError";
import { SchoolStudent } from "app/pods/student";
import { isSchoolLeader, isVerifiedSchoolAdmin, isVerifiedSchoolLeader } from "app/utils/school";
import assignIn from "lodash/assignIn";
import { useMemo } from "react";
import { useFetchedFeatureSwitch } from "app/pods/featureSwitches";
import { TEACHER_FEATURE_SWITCHES } from "app/pods/featureSwitches/constants";

export const STATE_KEY = "teacher";

export type TeacherSession = NonNullable<SessionResponse["teacher"]>;
export type BasicTeacher = NonNullable<SchoolStudent["latestTeacherGuess"]>;

/*
 * We use the `useFetched*` convention here. This means that by convention ApplicationContainer
 * will guarantee that whatever information this fetcher relies on, will be initialized
 * before rendering any children. The reasoning behind is that some info (like session,
 * userConfig, teacher, etc) should always be present the moment after we load the app.
 * By loading this information at initialization time, before rendering any screen, we can now
 * consume it without needing to add null checkers.
 * Is not an ideal solution but avoids unecessary null checks on fetchers that we always want to
 * be present after login. We might rework this with suspense in the short term.
 */
export const useFetchedTeacher = (): TeacherSession => {
  const { data } = useSessionFetcher({});
  const teacher = data && data.teacher;
  return teacher!;
};
/*
 * Returns if the teacher has the school.
 * it can be a verified or not verified in the school
 */
export const useHasSchool = (teacher: TeacherSession, schoolId?: string) => {
  return useMemo(() => {
    if (!schoolId) return false;
    const verified = teacher.schools.some((school) => school.schoolId === schoolId);
    if (verified) {
      return true;
    }
    return teacher.pendingSchools.some((school) => school.schoolId === schoolId);
  }, [teacher, schoolId]);
};

/*
 * Returns the SchoolTeacher row for the selected school.
 * it can be a verified or not verified school
 */
export const useFetchedTeacherSelectedSchool = () => {
  const teacher = useFetchedTeacher();
  const verifiedSelectedSchool = teacher.schools.find((s) => s.selected);
  if (verifiedSelectedSchool) return { ...verifiedSelectedSchool, isVerified: true };
  const pendingSelectedSchool = teacher.pendingSchools.find((school) => school.selected);
  return { ...pendingSelectedSchool, isVerified: false };
};

export function useSchoolId() {
  return useFetchedTeacherSelectedSchool().schoolId;
}

export function useSchoolIdParams() {
  const inCurrentSchoolAliasTest =
    !env.isTest &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useFetchedFeatureSwitch(TEACHER_FEATURE_SWITCHES.WEB_TEACH_FETCH_VIA_SCHOOL_CURRENT_ALIAS) === "test";
  const schoolId = useSchoolId();
  if (!schoolId) return NOOP;
  if (inCurrentSchoolAliasTest) return { schoolId: "current" };
  return { schoolId };
}

//
// Fetchers
export const useTeacherFetcher = makeMemberQuery({
  path: `/api/teacher/{id}`,
  fetcherName: "teacherIdFetcherMember",
});

export const useDistrictPrivilegesFetcher = makeMemberQuery({
  path: "/api/permission/district",
  fetcherName: "isDistrictLeader",
  dontThrowOnStatusCodes: [403],
});

/*
 * We use the `useFetched*` convention here. This means that by convention ApplicationContainer
 * will guarantee that whatever information this fetcher relies on, will be initialized
 * before rendering any children. The reasoning behind is that some info (like session,
 * userConfig, teacher, etc) should always be present the moment after we load the app.
 * By loading this information at initialization time, before rendering any screen, we can now
 * consume it without needing to add null checkers.
 * Is not an ideal solution but avoids unecessary null checks on fetchers that we always want to
 * be present after login. We might rework this with suspense in the short term.
 */
export const useFetchedTeacherSchoolId = () => {
  const teacher = useFetchedTeacher();
  const schoolId = teacher?.schoolId || undefined;
  return schoolId;
};

export const useIsTeacherSchoolAndEmailVerifiedLeader = () => {
  const teacher = useFetchedTeacher();
  const { schoolId } = teacher;
  const { data: school } = useSchoolFetcher(schoolId ? { id: schoolId } : NOOP);
  if (!school) return false;

  const schoolTeachers = getSchoolTeachers(school);
  return isVerifiedSchoolLeader(teacher, schoolTeachers) && teacher.emailVerified;
};

export type SendTeacherVerificationEmailParams = {
  body: APIRequestBody<"/api/sendEmailVerification", "post">;
};
export const useSendTeacherVerificationEmailOperation = makeApiMutation({
  name: "sendTeacherVerificationEmail",
  path: "/api/sendEmailVerification",
  method: "post",
});

export type SendTeacherVerificationEmailCodeParams = Record<string, never>;
export const useSendTeacherVerificationEmailCodeOperation = makeApiMutation({
  name: "sendTeacherVerificationEmailCode",
  path: "/api/sendEmailVerificationCode",
  method: "post",
});

export type UpdateTeacherProps = Partial<APIRequestBody<"/api/teacher/{id}", "put">>;
type UpdateTeacherOperationRequest = {
  props: UpdateTeacherProps;
  teacher: TeacherSession;
};

export const useUpdateTeacherOperation = makeMutation<UpdateTeacherOperationRequest, CallApiDefaultResponse, DojoError>(
  {
    name: "updateTeacher",
    fn: async ({ teacher, props }) => {
      try {
        return await callApi({
          method: "PUT",
          path: `/api/teacher/${teacher._id}`,
          body: assignIn(teacher, props),
        });
      } catch (err: any) {
        if (matchesError(err.response, "Email already exists")) {
          return errors.teacher.emailAlreadyExist();
        }
        if (matchesError(err.response, "Weak password: Password must not contain common passwords.")) {
          return errors.teacher.commonPassword();
        }

        throw new Error(getApiError(err.response) ?? "");
      }
    },
    onMutate: (params) => {
      useSessionFetcher.setQueriesData((draft) => {
        // TODO: TSM not elegant the draft.teacher! we need to dig into the types and probably make a better
        // type for the update situation
        draft.teacher = { ...draft.teacher!, ...params.props };
      });
    },
    onSuccess: (data, params) => {
      useSessionFetcher.setQueriesData((draft) => {
        const teacher = data.body.teacher || data.body;
        // TODO: TSM. We need to find a better way than the '!'
        // For some reason the response from updating a teacher is different than the 'get' of a teacher
        // we want to merge to not lose the props we had before (like isMentor)
        draft.teacher = { ...draft.teacher!, ...teacher };
      });
      useSchoolFetcher.invalidateQueries({ id: params.teacher.schoolId });
      useAllClassroomFetcher.invalidateQueries({});
      useCheckTeacherPhoneVerificationEligibility.invalidateQueries({});
    },
  },
);

export const useTeacherMailInteraction = makeApiMutation({
  name: "teacherMailInteraction",
  path: "/api/teacher/{teacherId}/mailInteraction",
  method: "post",
});

type DeleteTeacherParams = Partial<APIRequestParameters<"/api/session", "post">> & {
  teacherId: string;
  email: string | undefined;
  password: string;
};

type DeleteTeacherResponse = APIResponse<"/api/teacher/{id}", "delete">;

export const useStudentsForTeacherFetcher = makeCollectionQuery({
  path: "/api/studentsForTeacher",
  fetcherName: "possibleStudentsForConnectionRequests",
  query: { withoutDemo: "true" },
});
export type StudentForTeacher = APIResponse<"/api/studentsForTeacher", "get">["_items"][number];

export const useDeleteTeacherOperation = makeMutation<DeleteTeacherParams, DeleteTeacherResponse, DojoError>({
  name: "deleteTeacher",
  fn: async ({ teacherId, email, password }) => {
    try {
      // check if provided password is correct
      await callApi({
        method: "POST",
        path: "/api/session",
        query: {
          withUsageReport: true,
        },
        body: {
          login: email,
          password,
          type: "teacher",
        },
      });
    } catch (error: any) {
      if (error.response && matchesError(error.response, "Incorrect password")) {
        return errors.teacher.wrongPassword();
      }
      if (!error.response || !matchesError(error.response, "Must use one time code")) {
        throw error;
      }
    }

    // delete teacher
    await callApi({
      method: "DELETE",
      path: `/api/teacher/${teacherId}`,
    });
  },
});

export function useIsSchoolLeaderInUnownedClass({
  classroom,
  isInSchoolStory,
}: {
  classroom?: Classroom;
  isInSchoolStory?: boolean;
}) {
  const teacher = useFetchedTeacher();
  const isClassTeacher = !!classroom && userIsClassTeacher(classroom);

  const isAdminSchoolLeader = isSchoolLeader(teacher) && isVerifiedSchoolAdmin(teacher);

  if (!classroom && isInSchoolStory) return false;
  return isAdminSchoolLeader && !isClassTeacher;
}

export const useDeleteTeacherAccount = makeApiMutation({
  name: "deleteTeacherAccount",
  path: "/api/teacher/me",
  method: "delete",
  onSuccess: () => {
    useSessionFetcher.invalidateQueries();
  },
  useErrorBoundary: (error) => {
    if (isApiResponseError(error) && error.response.status === 401) {
      return false;
    }
    return true;
  },
});

export const useCheckTeacherPhoneVerificationEligibility = makeMemberQuery({
  fetcherName: "checkTeacherPhoneVerificationEligibility",
  path: "/api/dojoSchool/{schoolId}/phone-eligibility",
});

export const useSubmitTeacherPhoneVerification = makeApiMutation({
  name: "submitTeacherPhoneVerification",
  path: "/api/teacher/verification-info",
  method: "post",
  catchError: (error) => {
    if (error instanceof APIResponseError) {
      return errors.teacher.phoneVerificationEligibility();
    }
    throw error;
  },
});

export const useRequestSmsPhoneVerification = makeApiMutation({
  name: "requestSmsPhoneVerification",
  path: "/api/teacher/sms-verification-request",
  method: "put",
  catchError: (error) => {
    if (error instanceof APIResponseError) {
      return errors.teacher.phoneVerificationEligibility();
    }
    throw error;
  },
});

export const useVerifySmsPhoneVerification = makeApiMutation({
  name: "verifySmsPhoneVerification",
  path: "/api/teacher/sms-verification-confirm",
  method: "post",
  catchError: (error) => {
    if (error instanceof APIResponseError) {
      return errors.teacher.phoneVerificationEligibility();
    }
    throw error;
  },
});
