import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import {
  DDSNavigationMenuItem,
  DDSNavigationMenuContent,
  DDSNavigationMenuTrigger,
  DDSBadge,
  DDSHeadlineText,
  DDSLabelText,
  DDSBodyText,
} from "@web-monorepo/dds";
import styles from "./NotificationCenter.module.css";
import { IconAlertOn } from "@web-monorepo/dds-icons";

import { NotificationItem } from "./NotificationItem";
import { useNotificationCenterVM } from "./NotificationCenter.vm";

import { APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";

export type SchoolStudent = APIResponse<"/api/dojoSchool/{schoolId}/student", "get">["_items"][number];
export type ClassStudent = APIResponse<"/api/dojoClass/{classId}/student", "get">["_items"][number];

export type Student = SchoolStudent | ClassStudent;

export const NotificationCenter = ({
  requestsCount,
  useVM = useNotificationCenterVM,
  requests,
  targetId,
  targetType,
  Item,
}: {
  requestsCount?: number;
  requests?: React.ReactNode;
  targetType?: "class" | "school";
  targetId?: string;
  useVM?: typeof useNotificationCenterVM;
  Item?: typeof NotificationItem;
}): JSX.Element => {
  const { notifications, unreadCount, onOpen } = useVM({
    targetId,
    targetType,
  });

  const combinedCount = unreadCount + (requestsCount ?? 0);

  const ariaLabelNotifications = !combinedCount
    ? autoTranslate("Notification menu (0 notifications)")
    : combinedCount === 1
      ? autoTranslate("Notification menu (1 notification)")
      : autoTranslate("Notification menu (__count__ notifications)", {
          count: combinedCount.toFixed(),
        });

  return (
    <DDSNavigationMenuItem value="notifications">
      <DDSNavigationMenuTrigger
        data-name="notificationCenterButton"
        aria-label={ariaLabelNotifications}
        onClick={onOpen}
        showChevron={false}
        className={styles.trigger}
      >
        <IconAlertOn />
        <NotificationBadge count={combinedCount} />
      </DDSNavigationMenuTrigger>
      <DDSNavigationMenuContent className={styles.menuContent}>
        <DDSHeadlineText level={3} as="h2">
          Notifications
        </DDSHeadlineText>
        {requests && (
          <>
            <DDSLabelText level={2} as="h3">
              Requests
            </DDSLabelText>
            <div className={styles.requests}>{requests}</div>
            <DDSLabelText level={2} as="h3">
              Updates
            </DDSLabelText>
          </>
        )}
        <NotificationCenterContents notifications={notifications} Item={Item} />
      </DDSNavigationMenuContent>
    </DDSNavigationMenuItem>
  );
};

interface NotificationBadgeProps {
  count: number;
}

const NotificationBadge = ({ count }: NotificationBadgeProps) => {
  if (count > 0) {
    return (
      <div className={`${styles.badge} ${styles.unreadBadge}`}>
        <DDSBadge data-name={""} variant="destructive">
          {count}
        </DDSBadge>
      </div>
    );
  }
  return null;
};

const NotificationCenterContents = ({
  notifications,
  Item = NotificationItem,
}: Pick<ReturnType<typeof useNotificationCenterVM>, "notifications"> & {
  Item?: typeof NotificationItem;
}) => {
  if (!notifications) {
    return <DDSBodyText level={2}>Loading notifications...</DDSBodyText>;
  } else if (notifications.length === 0) {
    return <DDSBodyText level={2}>No recent notifications</DDSBodyText>;
  } else {
    return (
      <div className={styles.notifications}>
        {notifications.map((notification) => (
          <Item key={notification._id} notification={notification} />
        ))}
      </div>
    );
  }
  return null;
};
