import { useCallback } from "react";
import { makeApiMutation } from "../../reactQuery";
import { queryClient } from "../../reactQuery/queryClient";
import { makeQueryKey } from "../../reactQuery/queryKey";
import { captureException } from "@web-monorepo/telemetry";
import type { AugmentedBulletinThread } from "../types";
import { useParentBulletinThreadsFetcher, useSchoolTeacherUnifiedInboxBulletinThreadsFetcher } from "./fetchers";
import { useDebounce } from "@classdojo/web/hooks/useDebounce";

const useMarkBulletinsReadOperation = makeApiMutation({
  name: "markBulletinsRead",
  path: "/api/district/{districtId}/bulletin/mark-read",
  method: "post",
  retry: 0,
  onSuccess: (_data, params) => {
    if (useParentBulletinThreadsFetcher.getQueriesData().length) {
      useParentBulletinThreadsFetcher.setQueriesData((items) => {
        return items.map((item) => {
          if (item._id === params.path.districtId) {
            return {
              ...item,
              unreadCount: 0,
            };
          }

          return item;
        });
      });
      queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName: "parentUnreadsFetcher" }) });
    }
    if (useSchoolTeacherUnifiedInboxBulletinThreadsFetcher.getQueriesData().length) {
      useSchoolTeacherUnifiedInboxBulletinThreadsFetcher.setQueriesData((items) => {
        return items.map((item) => {
          if (item._id === params.path.districtId) {
            return {
              ...item,
              unreadCount: 0,
            };
          }

          return item;
        });
      });
      queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName: "schoolUnreadMessagesCount" }) });
    }
  },
});

export const useMarkBulletinsReadWrapperOperation = (thread?: AugmentedBulletinThread) => {
  const debouncedThread = useDebounce(thread, 1000);
  const { mutate: markReadMutation } = useMarkBulletinsReadOperation();

  const markRead = useCallback(() => {
    if (debouncedThread && debouncedThread.unreadCount > 0) {
      markReadMutation(
        {
          path: { districtId: debouncedThread._id },
        },
        {
          onError: (error: any) => {
            if (error.response && error.response.status >= 500) {
              return error;
            }

            if (error.response && error.response.status === 429) {
              captureException(error);
            }

            throw error;
          },
        },
      );
    }
  }, [debouncedThread, markReadMutation]);

  return markRead;
};
