import callApi from "@web-monorepo/infra/callApi";
import { makeMutation, queryClient, makeQueryKey } from "../../reactQuery";
import { isBulletinMessage, Message, type BulletinMessage } from "../types";
import { useMessagesFetcher, useParentBulletinMessagesFetcher } from "./fetchers";
import { InfiniteData } from "@tanstack/react-query";
import type { DojoCollectionPage } from "../../reactQuery/collectionQuery";

export const useMessageTranslationOperation = makeMutation<
  { messageThreadId?: string; messageId: string; language?: string; classId?: string; isBulletin?: boolean },
  Message | BulletinMessage
>({
  name: "translateMessage",
  fn: async ({ messageThreadId, messageId, language, classId, isBulletin }) => {
    if (!messageThreadId || !language) return;
    // is a classId is given, we will use the old resource;

    if (classId) {
      const response = await callApi({
        path: `/api/dojoClass/${classId}/message/${messageId}`,
        query: { translateTo: language },
      });

      return response.body;
    }
    if (isBulletin) {
      const response = await callApi({
        method: "POST",
        path: `/api/district/${messageThreadId}/bulletin/${messageId}/translate`,
        body: { language },
        headers: {
          "Cache-Control": "no-cache",
        },
      });

      return response.body;
    }
    const response = await callApi({
      method: "POST",
      path: `/api/message-thread/${messageThreadId}/message/${messageId}/translate`,
      body: { language },
      headers: {
        "Cache-Control": "no-cache",
      },
    });

    return response.body;
  },
  onSuccess: (data, params) => {
    useMessagesFetcher.setQueriesData((draft) => {
      if (!draft) return draft;
      for (const message of draft) {
        if (message._id === params.messageId) {
          message.translation = data.translation;
        }
      }
      return draft;
    });
    useParentBulletinMessagesFetcher.setQueriesData((draft) => {
      if (!draft) return draft;
      for (const bulletin of draft) {
        if (bulletin._id === params.messageId && isBulletinMessage(data)) {
          bulletin.translation = data.translation;
          bulletin.subjectTranslation = data.subjectTranslation;
        }
      }
      return draft;
    });
    queryClient.setQueriesData<InfiniteData<DojoCollectionPage<BulletinMessage>> | undefined>(
      makeQueryKey({ fetcherName: "teacherBulletinMessages" }),
      (draft) => {
        if (!draft) return draft;

        const updatedPages = draft.pages.map((page) => {
          const updatedItems = page._items.map((bulletin: BulletinMessage) => {
            if (bulletin._id === params.messageId && isBulletinMessage(data)) {
              return {
                ...bulletin,
                translation: data.translation,
                subjectTranslation: data.subjectTranslation,
              };
            }
            return bulletin;
          });
          return { ...page, _items: updatedItems };
        });
        return { ...draft, pages: updatedPages };
      },
    );
  },
});
