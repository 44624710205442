import { type FormEvent, useCallback, useEffect, useState } from "react";
import * as logClient from "@classdojo/log-client";
import { makeMemberQuery } from "@web-monorepo/shared/reactQuery";

const useSession = makeMemberQuery({
  fetcherName: "otcSession",
  path: "/api/session",
  dontThrowOnStatusCodes: [401],
});

const SIX_DIGITS_REGEX = /\d{6}/;

const isValidFormat = (code: string) => SIX_DIGITS_REGEX.test(code);

export const useViewModel = ({
  entityType,
  onCode,
}: {
  entityType: "teacher" | "parent";
  onCode(code: string): void;
}) => {
  const [verifying, setVerifying] = useState(false);
  const [code, setCode] = useState("");
  const { data: session } = useSession({});

  const canSubmit = !verifying && isValidFormat(code);

  const handleChange = useCallback((code: string) => setCode(code), [setCode]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (canSubmit) {
      setVerifying(true);
      onCode(code);
    }
  };

  const email = session?.[entityType]?.emailAddress;
  const instructions = email
    ? `We sent a one-time code to ${email}`
    : "We sent a one-time code to your email address";

  useEffect(() => {
    if (session) {
      logClient.sendMetrics([
        {
          metricName: "one_time_code.email_address_available",
          type: "increment",
          value: 1,
          tags: { result: email ? "available" : "unavailable" },
        },
      ]);
    }
  }, [session, email]);

  return {
    canSubmit,
    code,
    instructions,
    handleChange,
    handleSubmit,
    verifying,
  };
};
