import { logEvent } from "@classdojo/log-client";
import {
  CLIENT_ROLE,
  MESSAGE,
  RealtimeClient,
  PubnubAdapter,
  Exchange,
  IframeAdapter,
  createPublishSaga,
  createSubscribeSaga,
} from "@classdojo/web/pods/realtime";
import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import createAction from "@web-monorepo/infra/createAction";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import type { AnyAction } from "redux";
import { SagaIterator } from "redux-saga";
import { take, fork } from "redux-saga/effects";
import { connectToToolkit } from "app/utils/env";
import { metrics } from "app/utils/metrics";

declare global {
  interface Window {
    rtcClient: RealtimeClient;
  }
}

const STATE_KEY = "toolkit";

type ToolkitState = {
  displayVisible: boolean;
  remoteVisible: boolean;
};

type Slice = { [STATE_KEY]: ToolkitState };

const initialState: ToolkitState = { displayVisible: false, remoteVisible: false };

const DISPLAY_OPEN = createAction("DISPLAY_OPEN");
const DISPLAY_CLOSE = createAction("DISPLAY_CLOSE");
const REMOTE_OPEN = createAction("REMOTE_OPEN");
const REMOTE_CLOSE = createAction("REMOTE_CLOSE");
const RECEIVE_REMOTE_CLOSE = createAction("RECEIVE_REMOTE_CLOSE");
const TOOLKIT_IFRAME_INIT = createAction("TOOLKIT_IFRAME_INIT");
const TOOLKIT_REALTIME_SUBSCRIBER_INIT = createAction("TOOLKIT_REALTIME_SUBSCRIBER_INIT");

export const displayOpen = () => ({
  type: DISPLAY_OPEN,
  payload: {},
});

export const displayClose = () => ({
  type: DISPLAY_CLOSE,
  payload: {},
});

export const remoteOpen = () => ({
  type: REMOTE_OPEN,
  payload: {},
});

export const remoteClose = () => ({
  type: REMOTE_CLOSE,
  payload: {},
});

export type ToolkitIframeInitPayload = { iframeId: string; url: string };

export const toolkitIframeInit = (payload: ToolkitIframeInitPayload) => ({
  type: TOOLKIT_IFRAME_INIT,
  payload,
});

export type ToolkitRealtimeSubscriberInitPayload = { teacherId: string; mqttChannel: string };

type ToolkitRealtimeSubscriberInitAction = {
  type: typeof TOOLKIT_REALTIME_SUBSCRIBER_INIT;
  payload: ToolkitRealtimeSubscriberInitPayload;
};

export const toolkitRealtimeSubscriberInit = (
  payload: ToolkitRealtimeSubscriberInitPayload,
): ToolkitRealtimeSubscriberInitAction => ({
  type: TOOLKIT_REALTIME_SUBSCRIBER_INIT,
  payload,
});

function toolkitReducer(state: ToolkitState, { type }: AnyAction) {
  if (type === DISPLAY_OPEN) {
    return { ...state, displayVisible: true };
  }
  if (type === DISPLAY_CLOSE) {
    return { ...state, displayVisible: false };
  }
  if (type === REMOTE_OPEN) {
    return { ...state, remoteVisible: true };
  }
  if (type === REMOTE_CLOSE || type === RECEIVE_REMOTE_CLOSE) {
    return { ...state, remoteVisible: false };
  }
  return state;
}

function* realtimeSubscribeSaga(): SagaIterator<void> {
  // realtime client is gonna timeout for handshake in testing
  if (!connectToToolkit) return;

  const action: ToolkitRealtimeSubscriberInitAction = yield take(TOOLKIT_REALTIME_SUBSCRIBER_INIT);
  const exchange = new Exchange([new PubnubAdapter({ metrics, logEvent, userId: action.payload.teacherId })]);

  const channel = action.payload.mqttChannel;
  const client = new RealtimeClient(CLIENT_ROLE.HOST, channel ?? "", exchange);
  if (typeof window !== "undefined") {
    window.rtcClient = client;
  }
  yield fork(addIframeOnInitSaga, client);

  yield fork(createPublishSaga(client, mapFromAction));
  yield fork(createSubscribeSaga(client, mapToAction));
}

type ToolkitFrameInitAction = {
  payload: { iframeId: string };
};

const isHTMLIFrameElement = (element: HTMLElement): element is HTMLIFrameElement => {
  return "contentWindow" in element;
};

function* addIframeOnInitSaga(client: RealtimeClient) {
  while (true) {
    const action: ToolkitFrameInitAction = yield take(TOOLKIT_IFRAME_INIT);
    const { iframeId } = action.payload;
    const elementById = document.getElementById(iframeId);
    const iframe: HTMLIFrameElement | null = elementById && isHTMLIFrameElement(elementById) ? elementById : null;
    if (iframe && iframe.contentWindow) {
      let origin;
      try {
        origin = new URL(iframe.src).origin;
        // eslint-disable-next-line no-catch-all/no-catch-all
      } catch {
        // no worries, we can continue with an empty origin
      }
      client.addAdapter(new IframeAdapter(window, iframe.contentWindow, origin));
    }
    // otherwise we assume ideas initialized a pubnub instance and we don't have to do anything here
  }
}

// responsible for taking realtime messages and translating them to Redux actions
// exported for testing only
export function mapToAction({ type }: AnyAction) {
  switch (type) {
    case MESSAGE.DISPLAY_OPEN:
      return [{ type: DISPLAY_OPEN }, { type: RECEIVE_REMOTE_CLOSE }];
    case MESSAGE.DISPLAY_CLOSE:
      return { type: DISPLAY_CLOSE };
    case MESSAGE.REMOTE_CLOSE:
      return { type: RECEIVE_REMOTE_CLOSE };
    default:
      return null;
  }
}

// responsible for taking Redux actions and translating them to realtime messages
// exported for testing only
export function mapFromAction({ type }: AnyAction) {
  switch (type) {
    case REMOTE_CLOSE:
      return { type: MESSAGE.REMOTE_CLOSE };
    default:
      return null;
  }
}

export const selectDisplayVisible = (state: Slice) => state?.[STATE_KEY]?.displayVisible;
export const selectRemoteVisible = (state: Slice) => state?.[STATE_KEY]?.remoteVisible;

const reducer = combineActionHandlers(initialState, [toolkitReducer]);

const install: PodInstallFunction = (installReducer, installSaga) => {
  installReducer(STATE_KEY, reducer);
  installSaga(realtimeSubscribeSaga);
};

export default install;
